/**=====================
   55. theme layout CSS Start
==========================**/

// ========= theme layout 1 scss ==========//
body{
	&[main-theme-layout="main-theme-layout-1"]{
		.sidebar-widget {
			.sidebar-widget-bottom {
				background-color: lighten($dark-color, 3%);
				p{
					color: $dark-all-font-color;
				}
			}
			.sidebar-widget-top i {
				background-color: lighten($dark-color, 5%);
				color: $dark-all-font-color;
			}
		}
		.page-wrapper{
			.page-body-wrapper{
				&.sidebar-icon {
					.page-sidebar{
						.sidebar-menu {
							.sidebar-header{
								> i {
									color: $dark-all-font-color;
								}
							}
							li{
								background-color: $dark-card-background;
								&:hover{
									.single-header,
									.sidebar-submenu{
										background-color: $dark-card-background;
									}
								}
								a:hover + ul{
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				/* sidebar start */
				.page-sidebar {
					&.native-image-bg {
						background-blend-mode: overlay;
						background-color: #17191d;
					}
					&.navigation-bordered {
						.sidebar-header {
							border-bottom: 1px solid rgba(241, 241, 241, 0.15);
						}
					}
					background: $dark-card-background;
					.sidebar-user {
						background-color: $dark-card-background;
						box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
						h6 {
							color: $light-color;
						}
						p {
							color: $dark-all-font-color;
						}
					}
					.sidebar-menu {
						.sidebar-title {
							color: $primary-color;
							border-bottom: 1px solid rgba(241, 241, 241, 0.35);
						}
						>
						li {
							> a {
								color: $dark-all-font-color;
								&.active {
									color: $primary-color;
								}
								&:hover {
									color: $primary-color;
								}
							}
							.label {
								margin-top: 3px;
								margin-right: 5px;
							}
							&:hover > a {
								@extend %common;
								color: $dark-all-font-color;
							}
						}
						li {
							&.sidebar-header {
								color: $warning-color;
							}

						}
						a {
							color: $light-color;
						}
						.sidebar-submenu {
							> li {
								> a {
									color: $sidebar-submenu-font-color;
									&:hover {
										color: $primary-color;
									}
									&.active {
										color: $primary-color;
									}
								}
							}
						}
					}
					.sidebar-widget {
						border-top: 1px solid $dark-border-color;
						.sidebar-widget-top {
							i {
								background-color: $dark-card-background;
							}
							h6{
								color: $dark-all-font-color;
							}
						}
						.sidebar-widget-bottom {
							background-color: $dark-body-background;
						}
					}
				}
				/* sidebar end */
				.page-body{
					background-color: rgba(246, 246, 246, 0.6);
				}
				.bayu-body{
					background-color: rgba(246, 246, 246, 0.6);
				}
				.page-header{
					.breadcrumb{
						background-color: $transparent-color;
					}
				}
			}
		}
	}

}
// ========= theme layout 1 scss end here ==========//


// ========= theme layout 2  scss start ==========//

body{
	&[main-theme-layout="main-theme-layout-2"]{
		.styles_modal__gNwvD {
			background-color: $dark-card-background;
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
			}
			.modal-footer {
				border-top: 1px solid $dark-border-color;
			}
		}
		.grid-showcase {
			span {
				border: 1px solid $dark-border-color;
				background-color: $dark-card-background;
			}
		}
		.form-control {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
			color: $dark-small-font-color;
			&::placeholder {
				color: $dark-small-font-color;
			}
		}
		.dropdown-header {
			color: $dark-all-font-color;
		}
		.dropdown-item, span.twitter-typeahead .tt-suggestion {
			color: $dark-small-font-color;
		}
		.h-auto, .sweet-alert  {
			background-color: $dark-card-background !important;
		}
		.datatable-react {
			.react-bootstrap-table {
				.table-bordered {
					td, th {
						border-color: $dark-border-color;
					}
				}
				table {
					tbody {
						tr {
							&:nth-child(odd) {
								background-color: #303841;
								td {
									&:first-child {
										background-color: #2e353d;
									}
								}
								&:hover {
									td {
										background-color: #2e353d;
									}
								}
							}
							&:nth-child(even) {
								td {
									&:first-child {
										background-color: rgba(53, 59, 74, 0.3);
									}
								}
								&:hover {
									td {
										background-color: #2e353d;
										&:first-child {
											background-color: #2c3239;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.todo {
			.task-container {
				.table-responsive {
					table {
						td {
							border-top: none !important;
						}
					}
				}
			}
		}
		.todo-options {
			ul {
				li {
					a {
						color: $dark-all-font-color;
						&:hover {
							color: $primary-color;
						}
					}
				}
			}
		}
		.email-wrap {
			.email-right-aside {
				background-color: $dark-card-background;
			}
		}
		.product-table {
			table {
				border: 1px solid $dark-border-color;
			}
		}
		.flot-chart-container{
			.legend{
				.table{
					tbody{
						background-color: $dark-card-background;
						color: $white;
						.legendLabel{
							padding-left: 5px;
						}
					}
				}
			}
		}
		.nav-dark ,.nav-pills.nav-dark{
			.nav-link{
				color: $white;
			}
		}
		.page-wrapper{
			.page-main-header{
				color: $black;
				.default-according{
					.card{
						.btn-link {
							background-color: $white;
							border: 1px solid $light-color;
							color: $black;
						}
					}
				}
				.collapse{
					.card-body {
						background-color: $white;
					}
				}
				.theme-form {
					.form-group{
						input[type=text],
						input[type=email],
						textarea{
							border-color: $light-color;
							background-color: $white;
							color: $black;
						}
						label{
							color: $black;
						}
					}
				}
				#accordionoc {
					#collapseicon,
					#collapseicon1{
						background-color: $white;
					}
				}
				@media only screen and (max-width: 575px){
					.nav-right{
						> ul {
							.search-form {
								.mobile-search {
									filter: brightness(1) invert(100%);
								}
								.form-control-plaintext{
									background-color: $dark-body-background;
									filter: brightness(1) invert(9%) !important;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			.bayu-main-header{
				color: $black;
				.default-according{
					.card{
						.btn-link {
							background-color: $white;
							border: 1px solid $light-color;
							color: $black;
						}
					}
				}
				.collapse{
					.card-body {
						background-color: $white;
					}
				}
				.theme-form {
					.form-group{
						input[type=text],
						input[type=email],
						textarea{
							border-color: $light-color;
							background-color: $white;
							color: $black;
						}
						label{
							color: $black;
						}
					}
				}
				#accordionoc {
					#collapseicon,
					#collapseicon1{
						background-color: $white;
					}
				}
				@media only screen and (max-width: 575px){
					.nav-right{
						> ul {
							.search-form {
								.mobile-search {
									filter: brightness(1) invert(100%);
								}
								.form-control-plaintext{
									background-color: $dark-body-background;
									filter: brightness(1) invert(9%) !important;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			.page-body-wrapper{
				/* body start*/
				.page-body{
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
					}
				}
				.bayu-body{
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.1;
				}
				.page-header {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}
					.breadcrumb-item {
						&.active {
							color: $dark-small-font-color;
						}
					}
				}
				.page-body {
					background-color: $dark-body-background;
					color:$dark-all-font-color;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
						.card-header {
							background-color: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							> span {
								color: $dark-small-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-border-color;
								}
								.border-bottom-primary {
									th{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $sidebar-submenu-font-color;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-border-color;
							}
							tbody {
								.border-bottom-primary{
									th, td{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table-responsive{
							.table{
								th,td{
									border-top: 1px solid $dark-border-color;
								}
							}
						}
						.table[class*='bg-']{
							th, td{
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color:rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-border-color;
							}
						}
						.table-inverse{
							tbody {
								tr{
									&:nth-of-type(odd){
										&:hover {
											td{
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling{
							thead, tbody{
								th, td{
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $dark-card-background !important;
						}
						.business-top-widget {
							.media {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.business-card {
							table {
								td {
									color: $dark-small-font-color;
								}
								th {
									color: $dark-all-font-color;
								}
							}
							.logo{
								filter: brightness(1) invert(100%);
							}
						}
						.social-widget {
							.media {
								.social-sub-title {
									color: $dark-small-font-color;
								}
							}
							.total-value {
								color: $dark-all-font-color;
							}
							.social-icons {
								background-color: $dark-card-background;
							}
						}
						.ct-grid {
							stroke: $dark-small-font-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.review-box {
							.testimonial {
								.content {
									border: 1px solid $dark-border-color;
									&:before {
										border-bottom: 10px solid $dark-border-color;
									}
									&:after {
										border-bottom: 9px solid $dark-card-background;
									}
								}
							}
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark, .btn-outline-light-2x {
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.btn-light {
							color: $dark-body-background !important;
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						.crm-top-widget {
							.media {
								.media-body {
									h4 {
										color: $dark-all-font-color;
									}
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
						.stat-widget-dashboard {
							.media {
								img {
									filter: brightness(0) invert(75%);
								}
							}
							span {
								color: $dark-all-font-color;
							}
						}
						.support-ticket {
							table {
								tr {
									& + tr {
										border-top: 1px solid $dark-border-color;
									}
									td {
										p, h6 {
											color: $dark-small-font-color;
										}
										span {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.dataTables_wrapper {
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate{
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: #2e353d;
											}
										}
									}
									tr.odd {
										background-color: $dark-body-background;
										> .sorting_1 {
											background-color: #2e353d;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: rgba(53, 59, 74, 0.30);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th, td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: #2c3239;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: #2e353d;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							&:hover{
								background-color: $dark-border-color;
							}
						}
						.page-item.active {
							.page-link {
								background-color: $primary-color;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.crm-numbers {
							.col {
								+ .col {
									border-left: 1px solid $dark-border-color;
								}
								> span {
									color: $dark-small-font-color;
								}
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-box {
							.product-details {
								h6 {
									color: $dark-all-font-color;
								}
								span {
									color: $dark-small-font-color;
								}
							}
							.product-img {
								.product-hover {
									ul {
										li{
											background-color: $dark-card-background;
											&:hover{
												background-color: $black;
												color: $white;
											}
										}
									}
								}
							}
						}
						.product-table{
							#API-2_wrapper{
								#API-2{
									tbody{
										td{
											span, p{
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6{
								color: $dark-small-font-color;
							}
						}
						.nav-material.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								&.show{

								}
								.nav-link{
									color: $dark-all-font-color;
								}
							}
							.nav-link{
								&.active,
								&:focus,
								&:hover{
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal{
							.br-widget{
								a{
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected{
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget{
								a{
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background ;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected{
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget{
								a{
									&:after{
										color: $dark-border-color;
									}
									&.br-selected,
									&.br-active{
										&:after{
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo{
							border: 1px solid $dark-border-color;
						}
						.search-form{
							input{
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								filter: brightness(0) invert(100%);
							}
						}
						.cd-timeline-content{
							background-color: $dark-border-color;
							&::before{
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block{
							&:nth-child(even) {
								.cd-timeline-content{
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb{
							background-color: $dark-body-background;
						}
						.user-status{
							table{
								td, th{
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart{
							svg{
								opacity: 0.5;
							}
						}
					}
					.customer-review {
						.media-body {
							.cust-name {
								color: $dark-all-font-color;
							}
							.cust-des {
								color: $dark-small-font-color;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: #2e353d;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}

					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator{
							border: 1px solid $dark-border-color;
						}
					}
					.default-according{
						li{
							.text-muted{
								color: $dark-all-font-color!important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li{
							button{
								&:hover
								{
									color: $primary-color!important;
								}
							}
						}
					}
					.nav-list{
						.nav-list-disc{
							li{
								a{
									color: $dark-all-font-color;
								}
								&:hover{
									color: $primary-color;
									a{
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown{
						.onhover-show-div{
							background-color: $dark-card-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
						}
					}
					.onhover-dropdown{
						&:hover {
							.onhover-show-div{
								&:before{
									border-bottom: 7px solid $dark-card-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-right: 1px solid $dark-border-color;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}
						hr{
							border-top: 1px solid $dark-border-color;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					#cal-basic {
						.fc-toolbar {
							.fc-left, .fc-right {
								.fc-next-button {
									.fc-icon-right-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
								.fc-prev-button {
									.fc-icon-left-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
								}
								.fc-button-group{
									.fc-month-button{
										color: $white !important;
									}
								}
							}
							.fc-right{
								.btn-light{
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					#cal-basic-view{
						.fc-toolbar {
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-left {
								.fc-button-group {
									.fc-agendaWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.fc-button-group {
						.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
						}
					}
					#cal-agenda-view {
						.fc-toolbar {
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-right {
								.fc-button-group {
									.fc-month-button, .btn-light {
										color: $black !important;
									}
									.fc-agendaWeek-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.basic-calendar, #cal-bg-events, #cal-event-colors {
						.fc-toolbar {
							.fc-right {
								.fc-button-group {
									.fc-basicWeek-button , .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					.border-widgets {
						> .row {
							> div {
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
					.whether-widget {
						.whether-widget-top {
							background-color: $primary-color;
							.details {
								h4 {
									color: $white;
								}
							}
						}
						.whether-widget-bottom {
							h5 {
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					.default-widget-count {
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color
						}
						.icon-bg {
							color: #2e3945;
						}
						.left {
							i {
								color: $dark-body-background;
								text-shadow: 1px 1px 1px $dark-card-background;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.testimonial-default {
						.slide--item {
							p {
								color: $dark-all-font-color !important;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}

						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-border-color;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-border-color;
						}
					}
					.page-builder {
						.btn-grid{
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column{
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen{
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}
						.ge-canvas.ge-editing {
							.row{
								background-color: $dark-body-background;
							}
							.column{
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer{
								.ge-details{
									input{
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}
									.btn-group{
										a{
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor{
							color: $dark-all-font-color;
						}
					}
					.pricing-wrapper-card{
						.pricing-card{
							background-color: $dark-card-background;
						}
						.card-title{
							h3{
								color: rgba(206, 212, 218, 0.3);
							}
							h5{
								color: $dark-all-font-color;
							}
						}
						.card-price {
							h1{
								small{
									color: $dark-small-font-color;
								}
							}
						}
						.card-description{
							li{
								color: $dark-all-font-color;
								&::before {
									color: rgba(255,255,255,0.15);
								}
							}
						}
					}
					.search-page {
						.info-block{
							+ .info-block{
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute{
						.bg-primary, .bg-secondary{
							h5{
								color: $white;
							}
						}
					}
					/* body end*/
				}
				.bayu-body {
					background-color: $dark-body-background;
					color:$dark-all-font-color;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
						.card-header {
							background-color: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							> span {
								color: $dark-small-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-border-color;
								}
								.border-bottom-primary {
									th{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $sidebar-submenu-font-color;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-border-color;
							}
							tbody {
								.border-bottom-primary{
									th, td{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table-responsive{
							.table{
								th,td{
									border-top: 1px solid $dark-border-color;
								}
							}
						}
						.table[class*='bg-']{
							th, td{
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color:rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-border-color;
							}
						}
						.table-inverse{
							tbody {
								tr{
									&:nth-of-type(odd){
										&:hover {
											td{
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling{
							thead, tbody{
								th, td{
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $dark-card-background !important;
						}
						.business-top-widget {
							.media {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.business-card {
							table {
								td {
									color: $dark-small-font-color;
								}
								th {
									color: $dark-all-font-color;
								}
							}
							.logo{
								filter: brightness(1) invert(100%);
							}
						}
						.social-widget {
							.media {
								.social-sub-title {
									color: $dark-small-font-color;
								}
							}
							.total-value {
								color: $dark-all-font-color;
							}
							.social-icons {
								background-color: $dark-card-background;
							}
						}
						.ct-grid {
							stroke: $dark-small-font-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.review-box {
							.testimonial {
								.content {
									border: 1px solid $dark-border-color;
									&:before {
										border-bottom: 10px solid $dark-border-color;
									}
									&:after {
										border-bottom: 9px solid $dark-card-background;
									}
								}
							}
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark, .btn-outline-light-2x {
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.btn-light {
							color: $dark-body-background !important;
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						.crm-top-widget {
							.media {
								.media-body {
									h4 {
										color: $dark-all-font-color;
									}
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
						.stat-widget-dashboard {
							.media {
								img {
									filter: brightness(0) invert(75%);
								}
							}
							span {
								color: $dark-all-font-color;
							}
						}
						.support-ticket {
							table {
								tr {
									& + tr {
										border-top: 1px solid $dark-border-color;
									}
									td {
										p, h6 {
											color: $dark-small-font-color;
										}
										span {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.dataTables_wrapper {
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate{
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: #2e353d;
											}
										}
									}
									tr.odd {
										background-color: $dark-body-background;
										> .sorting_1 {
											background-color: #2e353d;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: rgba(53, 59, 74, 0.30);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th, td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: #2c3239;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: #2e353d;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							&:hover{
								background-color: $dark-border-color;
							}
						}
						.page-item.active {
							.page-link {
								background-color: $primary-color;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.crm-numbers {
							.col {
								+ .col {
									border-left: 1px solid $dark-border-color;
								}
								> span {
									color: $dark-small-font-color;
								}
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-box {
							.product-details {
								h6 {
									color: $dark-all-font-color;
								}
								span {
									color: $dark-small-font-color;
								}
							}
							.product-img {
								.product-hover {
									ul {
										li{
											background-color: $dark-card-background;
											&:hover{
												background-color: $black;
												color: $white;
											}
										}
									}
								}
							}
						}
						.product-table{
							#API-2_wrapper{
								#API-2{
									tbody{
										td{
											span, p{
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6{
								color: $dark-small-font-color;
							}
						}
						.nav-material.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								&.show{

								}
								.nav-link{
									color: $dark-all-font-color;
								}
							}
							.nav-link{
								&.active,
								&:focus,
								&:hover{
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal{
							.br-widget{
								a{
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected{
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget{
								a{
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background ;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected{
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget{
								a{
									&:after{
										color: $dark-border-color;
									}
									&.br-selected,
									&.br-active{
										&:after{
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo{
							border: 1px solid $dark-border-color;
						}
						.search-form{
							input{
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								filter: brightness(0) invert(100%);
							}
						}
						.cd-timeline-content{
							background-color: $dark-border-color;
							&::before{
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block{
							&:nth-child(even) {
								.cd-timeline-content{
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb{
							background-color: $dark-body-background;
						}
						.user-status{
							table{
								td, th{
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart{
							svg{
								opacity: 0.5;
							}
						}
					}
					.customer-review {
						.media-body {
							.cust-name {
								color: $dark-all-font-color;
							}
							.cust-des {
								color: $dark-small-font-color;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: #2e353d;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}

					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator{
							border: 1px solid $dark-border-color;
						}
					}
					.default-according{
						li{
							.text-muted{
								color: $dark-all-font-color!important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li{
							button{
								&:hover
								{
									color: $primary-color!important;
								}
							}
						}
					}
					.nav-list{
						.nav-list-disc{
							li{
								a{
									color: $dark-all-font-color;
								}
								&:hover{
									color: $primary-color;
									a{
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown{
						.onhover-show-div{
							background-color: $dark-card-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
						}
					}
					.onhover-dropdown{
						&:hover {
							.onhover-show-div{
								&:before{
									border-bottom: 7px solid $dark-card-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-right: 1px solid $dark-border-color;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}
						hr{
							border-top: 1px solid $dark-border-color;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					#cal-basic {
						.fc-toolbar {
							.fc-left, .fc-right {
								.fc-next-button {
									.fc-icon-right-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
								.fc-prev-button {
									.fc-icon-left-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
								}
								.fc-button-group{
									.fc-month-button{
										color: $white !important;
									}
								}
							}
							.fc-right{
								.btn-light{
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					#cal-basic-view{
						.fc-toolbar {
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-left {
								.fc-button-group {
									.fc-agendaWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.fc-button-group {
						.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
						}
					}
					#cal-agenda-view {
						.fc-toolbar {
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-right {
								.fc-button-group {
									.fc-month-button, .btn-light {
										color: $black !important;
									}
									.fc-agendaWeek-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.basic-calendar, #cal-bg-events, #cal-event-colors {
						.fc-toolbar {
							.fc-right {
								.fc-button-group {
									.fc-basicWeek-button , .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					.border-widgets {
						> .row {
							> div {
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
					.whether-widget {
						.whether-widget-top {
							background-color: $primary-color;
							.details {
								h4 {
									color: $white;
								}
							}
						}
						.whether-widget-bottom {
							h5 {
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					.default-widget-count {
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color
						}
						.icon-bg {
							color: #2e3945;
						}
						.left {
							i {
								color: $dark-body-background;
								text-shadow: 1px 1px 1px $dark-card-background;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.testimonial-default {
						.slide--item {
							p {
								color: $dark-all-font-color !important;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}

						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-border-color;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-border-color;
						}
					}
					.page-builder {
						.btn-grid{
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column{
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen{
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}
						.ge-canvas.ge-editing {
							.row{
								background-color: $dark-body-background;
							}
							.column{
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer{
								.ge-details{
									input{
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}
									.btn-group{
										a{
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor{
							color: $dark-all-font-color;
						}
					}
					.pricing-wrapper-card{
						.pricing-card{
							background-color: $dark-card-background;
						}
						.card-title{
							h3{
								color: rgba(206, 212, 218, 0.3);
							}
							h5{
								color: $dark-all-font-color;
							}
						}
						.card-price {
							h1{
								small{
									color: $dark-small-font-color;
								}
							}
						}
						.card-description{
							li{
								color: $dark-all-font-color;
								&::before {
									color: rgba(255,255,255,0.15);
								}
							}
						}
					}
					.search-page {
						.info-block{
							+ .info-block{
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute{
						.bg-primary, .bg-secondary{
							h5{
								color: $white;
							}
						}
					}
					/* body end*/
				}
				footer {
					border-top: 1px solid $dark-body-background;
					background-color: $dark-card-background;
				}
				.custom-select, .custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}
				.footer-fix{
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}
			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;
				h2 {
					background-color: $dark-card-background;
				}
			}
			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}
				.chat-menu {
					border-left: 1px solid $dark-border-color;
					.nav-tabs {
						border-bottom: 1px solid $dark-border-color;
						.nav-item {
							.nav-link {
								&.active {
									color: $dark-all-font-color !important;
								}
							}
						}
					}

					.user-profile {
						.image {
							.icon-wrapper {
								background-color: $dark-card-background;
								box-shadow: 1px 1px 3px 1px $dark-body-background;
							}
							.avatar{
								img{
									border: 5px solid $dark-border-color;
								}
							}
						}
						.social-media {
							a {
								color: $dark-small-font-color;
							}
						}
						.follow {
							span {
								color: $dark-small-font-color;
							}
							.follow-num {
								color: $dark-all-font-color;
							}
						}
					}
				}
				.status {
					color: $dark-small-font-color;
					p {
						color: $dark-all-font-color !important;
					}
				}
				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid $dark-border-color;
							.chat-menu-icons {
								li {
									a {
										i {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.chat-msg-box {
							.my-message {
								border: 1px solid $dark-border-color
							}
							.message {
								color: $dark-all-font-color;
							}
							.other-message {
								background-color: $dark-body-background;
							}
						}
						.chat-message {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
							.input-group {
								.form-control {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.chat-history {
					.call-icons {
						ul {
							li {
								border: 1px solid $dark-border-color;
								a {
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
			}
			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}
			.scorlled {
				background-color: $dark-body-background;
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}
			.input-group-solid {
				.input-group-text, .form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
			.semilight-bg-color, .header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: $dark-all-font-color;
				&.active {
					background-color: $primary-color;
					border-color: $primary-color;
					color: $white;
				}
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
								}
								label {
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									color: $dark-all-font-color;
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
					h3, h4, h6{
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4, h3 {
						color: $white;
					}
					h6 {
						color: $dark-small-font-color;
					}
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input[type=text],
								input[type=password] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}
						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}
			.modal-content {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.2);
				.modal-header {
					border-bottom: 1px solid $dark-border-color;
					.close {
						color: $dark-small-font-color;
					}
				}
				.modal-footer {
					border-top: 1px solid $dark-border-color;
				}
			}
			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.8);
				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}
					.countdown {
						border-top: 1px solid $dark-border-color;
						border-bottom: 1px solid $dark-border-color;
						.title {
							color: $dark-all-font-color;
						}
					}
					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
					img{
						filter: brightness(1) invert(100%);
					}
				}
			}
			.authentication-main {
				.auth-innerleft {
					background: url(/assets/images/auth-layer.png),  rgba(54, 64, 74, 0.9);
					background-blend-mode: overlay;
					.text-center {
						img {
							filter: brightness(1) invert(100%);
						}
					}
					.social-media{
						li{
							color: $dark-body-background;
						}
					}
				}
				.auth-innerright {
					background-color: $dark-border-color;
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}
						.reset-password-link {
							color: $dark-small-font-color;
						}
						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}
								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-border-color;
								}
							}
							.opt-box {
								background-color: $dark-border-color;
							}
						}

					}
					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-small-font-color;
						}
						h3{
							color: $dark-all-font-color;
						}
						.card {
							background-color: $dark-card-background;
							.theme-form {
								.form-group {
									input[type=text],
									input[type=password] {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
										color: $dark-all-font-color;
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-border-color;
										}
									}
								}
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: $dark-card-background;
					.btn-link{
						background-color: $dark-card-background;
						border: 1px solid $dark-card-background;
						color: $white;
					}
					.text-muted{
						color: $dark-small-font-color !important;
					}
				}
				.bg-primary{
					.btn-link{
						background-color: $primary-color;
						border: 1px solid $primary-color;
					}
				}
				.bg-secondary{
					.btn-link{
						background-color: $secondary-color;
						border: 1px solid $secondary-color;
					}
				}
			}
			.collapse{
				.card-body{
					background-color: $dark-card-background;
				}
			}
			@media screen and (max-width: 1660px){
				.chat-box{
					.chat-history{
						.call-icons{
							ul{
								li{
									border: 1px solid $dark-border-color;
								}
							}
						}
						.total-time{
							h2{
								color: $dark-all-font-color;
							}
						}
					}
				}
				.caller-img{
					img{
						opacity: 0.7;
					}
				}
				.chat-box {
					.chat-history {
						.call-content {
							>div{
								background-color: rgba(0, 0, 0, 0.75);
								background-blend-mode: overlay;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1366px) {
				//crm dashboard
				.crm-numbers {
					.col {
						border-bottom: 1px solid $dark-border-color;
						+.col{
							border-left: 1px solid $dark-border-color;
							& + .col{
								border-left: 1px solid $dark-border-color;
								& + .col{
									border-left:1px solid $dark-border-color;
								}
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1199px){
				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}
				.error-wrapper{
					.maintenance-icons{
						li{
							i{
								color: $dark-color;
							}
						}
					}
				}
				.border-widgets{
					> .row{
						>div:nth-child(1) {
							border-bottom: 1px solid $dark-border-color;
						}
						>div:nth-child(2) {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
				.crm-numbers {
					.col {
						+.col{
							border-left: 1px solid $dark-border-color;
							& + .col{
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 991px){
				.crm-numbers {
					.col {
						+.col{
							border-left:1px solid $dark-border-color;
						}
					}
				}
			}
			@media only screen and (max-width: 767px){
				//crm dashboard
				.border-widgets{
					> .row{
						> div{
							+div{
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 575px){
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid $dark-border-color;
								border-bottom: 1px solid $dark-border-color;
							}
						}
					}
				}
				.border-widgets {
					> .row {
						> div {
							+ div {
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
				.widget-joins {
					.border-after-xs {
						&:after {

							background-color: $dark-border-color;
						}
					}
				}
			}
			@media only screen and (max-width: 480px){
				.business-card {
					.b-l-light{
						border-left: 1px solid $dark-border-color!important;
					}
				}
			}
			@media only screen and (max-width: 360px){
				.border-widgets{
					> .row{
						> div{
							& + div{
								& + div{
									border-bottom: 1px solid $dark-border-color;
								}
							}
						}
					}
				}
			}
		}
		.lg-backdrop {
			background-color: $dark-body-background;
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}
		.drag {
			background-color: $dark-card-background;
			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;
				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}
		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			p {
				color: $dark-small-font-color;
			}
			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			.radio, .checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}
		.select2-container--default{
			.select2-selection--multiple, .select2-selection--single{
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color !important;
			}
			.select2-search--inline{
				.select2-search__field{
					color: $dark-all-font-color;
				}
			}
			.select2-selection--single{
				.select2-selection__rendered{
					color: $dark-all-font-color;
				}
			}
			.select2-search--dropdown {
				.select2-search__field{
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
		}
		.select2-dropdown{
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: $primary-color !important;
				color: $primary-color !important;
			}
			.form-control-secondary {
				border-color: $secondary-color !important;
				color: $secondary-color !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill{
				background-color: $primary-color !important;
				color: $white !important;
			}
			.form-control-secondary-fill{
				background-color: $secondary-color !important;
				color: $white !important;
			}
			.form-control-success-fill{
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill{
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill{
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill{
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill{
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled{
			.select2-selection--single{
				background-color: $dark-border-color;
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color;
				}
				input[type=text], input[type=email], input[type=search],
				input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local], input[type=time], input[type=datetime-local], input[type=month], input[type=week],
				input[type=url], input[type=file], select {
					border-color: $dark-border-color;
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}
				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
				textarea {
					border-color: $dark-border-color;
				}
			}
			.form-divider{
				border-top: 1px solid $dark-border-color;
				&::before{
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.CodeMirror{
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;
			.CodeMirror-code{
				pre{
					background: $dark-card-background;
				}
			}
		}
		.editor-toolbar{
			border-top: 1px solid $dark-small-font-color;
			border-left: 1px solid $dark-small-font-color;
			border-right: 1px solid $dark-small-font-color;
			a{
				color: $dark-all-font-color !important;
				&:hover,
				&.active{
					background: $dark-border-color;
				}
			}
			i.separator{
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}
		}
		.editor-toolbar.disabled-for-preview {
			a{
				&:not(.no-disable){
					background: $dark-border-color;
				}
			}
		}
		.editor-preview {
			background-color: $dark-card-background;
		}
		.editor-toolbar.fullscreen, .editor-preview-side{
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}
		.u-step{
			background: $dark-border-color;
			&.active,
			&.current{
				background: $primary-color;
				color: $white;
			}
		}
		.u-step-title, .u-pearl-title{
			color: $dark-all-font-color;
		}
		.u-step-number{
			background-color: $dark-card-background;
		}
		.u-pearl{
			&:before{
				background-color: $dark-border-color;
			}
		}
		.u-pearl-number, .u-pearl-icon{
			background: $dark-body-background;
			border: 2px solid $dark-body-background;
		}
		.u-pearl.disabled {
			.u-pearl-icon, .u-pearl-number{
				background: $dark-border-color;
				border: 2px solid $dark-border-color;
			}
			&:after {
				background-color: #2e353d;
			}
		}
		.u-pearl.error{
			&:after{
				background-color: $dark-body-background;
			}
		}
		.note-editor.note-frame{
			border-color: $dark-border-color;
			.note-editing-area {
				.note-editable{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.swal-modal{
			background-color: $dark-card-background;
			.swal-title{
				color: $dark-all-font-color;
			}
			.swal-text{
				color: $dark-small-font-color;
			}
			.swal-content__input{
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}
		.nav-tabs{
			border-bottom: 1px solid $dark-border-color;
			.nav-link{
				&.active{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
				&:hover,
				&:focus{
					border-color: $dark-border-color $dark-border-color $dark-border-color;
				}
			}
			.nav-item.show{
				.nav-link{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-small-font-color $dark-border-color $dark-card-background;
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link{
					&.active,
					&.focus,
					&.hover{
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}
		.nav-material.nav-left, .nav-material.nav-right {
			.nav-link{
				color: $dark-all-font-color;
				&.active{
					color: $primary-color;
				}
			}
			.show {
				> .nav-link{
					color: $primary-color;
				}
			}
		}
		.nav-material.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					border-left-color: $secondary-color;
					color: $secondary-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $secondary-color;
				color: $secondary-color !important;
			}
			.nav-item.show{
				color: $secondary-color !important;
				border-left-color: $secondary-color;
			}
		}
		.nav-material.nav-left.nav-success{
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show{
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.nav-material.nav-right.nav-info{
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show{
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.nav-material.nav-secondary {
			.nav-link{
				&.active,
				&:focus,
				&:hover{
					color: $secondary-color !important;
				}
			}
			.nav-item{
				&.show{
					color: $secondary-color !important;
				}
			}
		}
		.nav-material.nav-success {
			.nav-link{
				&.active,
				&:focus,
				&:hover{
					color: $success-color !important;
				}
			}
			.nav-item{
				&.show{
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider{
			border-top: 1px solid $dark-border-color;
		}
		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-border-color;
			.icon-title {
				color: $dark-all-font-color;
			}
			span {
				color: $dark-small-font-color;
			}
			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}
		code {
			background-color: #434f5a;
		}
		#cd-timeline{
			&::before {
				background: $dark-border-color;
			}
		}
		.timeliny{
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
			&::before{
				background-color: $white;
			}
			.timeliny-dot{
				background-color: $dark-card-background;
				border: 1px solid $white;
				&::before {
					color: $dark-all-font-color;
				}
			}
			.timeliny-timeline{
				.timeliny-timeblock{
					&.active{
						.timeliny-dot{
							&::before{
								color: $dark-all-font-color;
							}
						}
					}
					&:not(.inactive):not(.active){
						.timeliny-dot{
							&:hover {
								background-color: $white;
								&::before{
									color: $dark-all-font-color;
								}
							}
						}
					}
					.timeliny-dot{
						&:hover{
							&::after{
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.todo{
			.todo-list-wrapper{
				#todo-list{
					li {
						.task-container{
							background: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							.task-label{
								color: $dark-all-font-color;
							}
							&:hover{
								h4{
									color: $white;
								}
							}
							.task-action-btn{
								.action-box{
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
						&.completed{
							.task-container{
								.task-label{
									color: $primary-color;
								}
								.complete-btn{
									&:hover{
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper{
						textarea{
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							color: $dark-all-font-color;
						}
					}
				}

			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: $dark-small-font-color;
				}
				span {
					color: $dark-all-font-color;
				}
			}
			.hovercard {
				.info {
					.title {
						a{
							color: $dark-all-font-color;
						}
					}
				}
				.user-image{
					.avatar{
						img{
							border: 10px solid $dark-card-background;
						}
					}
					.icon-wrapper{
						background-color: $dark-card-background;
					}
				}
			}
			.follow {
				.follow-num{
					color: $dark-all-font-color;
				}
			}
			.profile-img-style{
				.user-name{
					color: $dark-all-font-color;
				}
				p{
					color: $dark-small-font-color;
				}
			}
		}
		.introjs-helperLayer{
			background-color: rgba(54,64,74,0.9);
			border: rgba(255,255,255,0.5);
		}
		.jstree-default {
			.jstree-node, .jstree-icon{
				color: #434f5a !important;
			}
			.jstree-anchor{
				color: $dark-small-font-color;
			}
			.jstree-clicked{
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered{
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
			}
		}
		span.twitter-typeahead{
			.tt-menu{
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
			.tt-suggestion{
				color: $dark-all-font-color;
				&:hover,
				&:focus{
					background-color: $dark-card-background;
				}
			}
		}
		.typography{
			small{
				color: $dark-all-font-color;
			}
		}
		.blockquote-footer{
			color: $dark-small-font-color;
		}
		.quickview-wrapper {
			background-color: $dark-card-background;
			box-shadow: 0 0 9px $dark-border-color;
			border-left: 1px solid $dark-border-color;
			.quickview-box, .quickview-recent-activity {
				&:before {
					background-color: $dark-card-background;
					box-shadow: 0 0 9px $dark-border-color;
					border-left: 1px solid $dark-border-color;
				}
				&:after {
					background-color: $dark-card-background;
				}
			}
			.nav-material.nav-tabs {
				.nav-item {
					.nav-link {
						color: $dark-all-font-color;
					}
				}
			}
			.nav-material.nav-primary {
				.nav-link {
					&.active, :hover {
						color: $primary-color;
						background-color: transparent;
					}
				}
				.nav-item.show, .nav-link:focus {
					color: $primary-color;
					background-color: transparent;
				}
			}
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
				.modal-title {
					color: $dark-all-font-color;
				}
				i {
					color: $dark-small-font-color;
				}
			}
			.friend-list-search {
				background-color: #2f3841;
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.recent-activity {
				.activity-content {
					h6 {
						color: $dark-all-font-color;
					}
					hr {
						border-top: 1px solid $dark-border-color;
					}
				}
				.user-first-letter {
					&:after {
						border: 1px dashed $dark-border-color;
					}
				}
			}
			.chat-box{
				.about{
					.name{
						color: $dark-all-font-color;
					}
				}
			}
		}
		.code-box-copy{
			pre{
				background-color: $dark-body-background;
				code{
					background-color: $dark-body-background;
				}
			}
			pre[class*="language-"]{
				border: 1px solid $dark-border-color;
			}
			.code-box-copy__btn{
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color:$white;
			}
			code[class*=language-], pre[class*=language-]{
				text-shadow: 0px 1px $black;
				::selection{
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}
		table.fixedHeader-floating{
			background-color: $dark-body-background;
		}
		.dt-button-info{
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;
			h2{
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}
		#keytable_wrapper, #column-selector_wrapper, #auto-fill_wrapper, #custom-button_wrapper, #class-button_wrapper, #keyboard-btn_wrapper, #multilevel-btn_wrapper, #pagelength-btn_wrapper, #basic-colreorder_wrapper, #state-saving_wrapper, #real-time_wrapper, #basic-fixed-header_wrapper, #fixed-header-footer_wrapper, #export-button_wrapper, #excel-cust-bolder_wrapper, #cust-json_wrapper, #basic-key-table_wrapper, #focus-cell_wrapper, #responsive_wrapper, #new-cons_wrapper, #show-hidden-row_wrapper, #basic-row-reorder_wrapper, #full-row_wrapper, #rest-column_wrapper{
			.dataTables_paginate{
				border: none;
			}
		}
		#example-style-8_wrapper{
			table.dataTable.stripe, table.dataTable.display{
				tbody {
					tr.odd{
						background-color: $dark-border-color;
					}
				}
			}
		}
		.error-wrapper{
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content{
				color: $dark-all-font-color;
			}
		}
		.b-light{
			border:1px solid $dark-border-color !important;
		}
		.modal-content{
			background-color: $dark-card-background;
			.modal-header{
				border-bottom: 1px solid $dark-border-color;
			}
			.ui-front{
				.form-control{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
		}
		.stepwizard {
			.stepwizard-row{
				&:before{
					background-color: $dark-small-font-color;
				}
			}
		}
		.modal{
			.theme-close{
				background-color: $dark-card-background !important;
			}
		}
		.token.atrule, .token.attr-value, .token.keyword {
			color: $info-color;
		}
		.token{
			&.boolean, &.constant, &.deleted, &.number, &.property, &.symbol, &.tag{
				color: $danger-color;
			}
		}
		.loader-box{
			&.card-loader{
				background-color: $dark-card-background;
			}
		}
		.my-gallery{
			&.gallery-with-description{
				img {
					border: 1px solid $dark-border-color !important;
					border-bottom: none!important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse{
				color: $dark-all-font-color;
			}
		}
	}
}
// ========= theme layout 2  scss end here ==========//

// ========= theme layout 3  scss start ==========//

body{
	&[main-theme-layout="main-theme-layout-3"] {
		color: $dark-all-font-color;
		background-color: $dark-body-background;
		.styles_modal__gNwvD {
			background-color: $dark-card-background;
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
			}
			.modal-footer {
				border-top: 1px solid $dark-border-color;
			}
		}
		.grid-showcase {
			span {
				border: 1px solid $dark-border-color;
				background-color: $dark-card-background;
			}
		}
		.form-control {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
			color: $dark-small-font-color;
			&::placeholder {
				color: $dark-small-font-color;
			}
		}
		.dropdown-header {
			color: $dark-all-font-color;
		}
		.dropdown-item, span.twitter-typeahead .tt-suggestion {
			color: $dark-small-font-color;
		}
		.h-auto, .sweet-alert {
			background-color: $dark-card-background !important;
		}
		.datatable-react {
			.react-bootstrap-table {
				.table-bordered {
					td, th {
						border-color: $dark-border-color;
					}
				}
				table {
					tbody {
						tr {
							&:nth-child(odd) {
								background-color: #303841;
								td {
									&:first-child {
										background-color: #2e353d;
									}
								}
								&:hover {
									td {
										background-color: #2e353d;
									}
								}
							}
							&:nth-child(even) {
								td {
									&:first-child {
										background-color: rgba(53, 59, 74, 0.3);
									}
								}
								&:hover {
									td {
										background-color: #2e353d;
										&:first-child {
											background-color: #2c3239;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.todo {
			.task-container {
				.table-responsive {
					table {
						td {
							border-top: none !important;
						}
					}
				}
			}
		}
		.todo-options {
			ul {
				li {
					a {
						color: $dark-all-font-color;
						&:hover {
							color: $primary-color;
						}
					}
				}
			}
		}
		.email-wrap {
			.email-right-aside {
				background-color: $dark-card-background;
			}
		}
		.product-table {
			table {
				border: 1px solid $dark-border-color;
			}
		}
		.flot-chart-container{
			.legend{
				.table{
					tbody{
						background-color: $dark-card-background;
						color: $white;
						.legendLabel{
							padding-left: 5px;
						}
					}
				}
			}
		}
		.nav-dark ,.nav-pills.nav-dark{
			.nav-link{
				color: $white;
			}
		}
		.page-wrapper {
			.page-main-header{
				color: $black;
				.default-according{
					.card{
						.btn-link {
							background-color: $white;
							border: 1px solid $light-color;
							color: $black;
						}
					}
				}
				.collapse{
					.card-body {
						background-color: $white;
					}
				}
				.theme-form {
					.form-group{
						input[type=text],
						input[type=email],
						textarea{
							border-color: $light-color;
							background-color: $white;
							color: $black;
						}
						label{
							color: $black;
						}
					}
				}
				#accordionoc {
					#collapseicon,
					#collapseicon1{
						background-color: $white;
					}
				}
				@media only screen and (max-width: 575px){
					.nav-right{
						> ul {
							.search-form {
								.mobile-search {
									filter: brightness(1) invert(100%);
								}
								.form-control-plaintext{
									background-color: $dark-body-background;
									filter: brightness(1) invert(9%) !important;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			.bayu-main-header{
				color: $black;
				.default-according{
					.card{
						.btn-link {
							background-color: $white;
							border: 1px solid $light-color;
							color: $black;
						}
					}
				}
				.collapse{
					.card-body {
						background-color: $white;
					}
				}
				.theme-form {
					.form-group{
						input[type=text],
						input[type=email],
						textarea{
							border-color: $light-color;
							background-color: $white;
							color: $black;
						}
						label{
							color: $black;
						}
					}
				}
				#accordionoc {
					#collapseicon,
					#collapseicon1{
						background-color: $white;
					}
				}
				@media only screen and (max-width: 575px){
					.nav-right{
						> ul {
							.search-form {
								.mobile-search {
									filter: brightness(1) invert(100%);
								}
								.form-control-plaintext{
									background-color: $dark-body-background;
									filter: brightness(1) invert(9%) !important;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			.page-body-wrapper {
				/* sidebar start */
				&.sidebar-icon {
					.page-sidebar{
						.sidebar-menu {
							.sidebar-header{
								> i {
									color: $dark-all-font-color;
								}
							}
							li{
								background-color: $dark-card-background;
								&:hover{
									.single-header,
									.sidebar-submenu{
										background-color: $dark-card-background;
									}
								}
								a:hover + ul{
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.page-sidebar {
					background: $dark-card-background;
					background-blend-mode: overlay;
					&.native-image-bg {
						background-blend-mode: overlay;
						background-color: #17191d;
					}
					&.navigation-bordered {
						.sidebar-header {
							border-bottom: 1px solid rgba(241, 241, 241, 0.35);
						}
					}
					.sidebar-user {
						background-color: $dark-card-background;
						box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
						h6 {
							color: $light-color;
						}
						p {
							color: $white;
						}
					}
					.sidebar-menu {
						.sidebar-title {
							color: $primary-color;
							border-bottom: 1px solid rgba(241, 241, 241, 0.35);
						}
						>
						li {
							> a {
								color: $dark-all-font-color;
								&.active {
									color: $primary-color;
								}
								&:hover {
									color: $primary-color;
								}
							}
							.label {
								margin-top: 3px;
								margin-right: 5px;
							}
							&:hover > a {
								@extend %common;
								color: $dark-all-font-color;
							}
						}
						li {
							&.sidebar-header {
								color: $warning-color;
							}

						}
						a {
							color: $light-color;
						}
						.sidebar-submenu {
							> li {
								> a {
									color: $sidebar-submenu-font-color;
									&:hover {
										color: $primary-color;
									}
									&.active {
										color: $primary-color;
									}
								}
							}
						}
					}
					.sidebar-widget {
						border-top: 1px solid $dark-border-color;
						.sidebar-widget-top {
							i {
								background-color: $dark-card-background;
							}
						}
						.sidebar-widget-bottom {
							background-color: $dark-body-background;
						}
					}
				}
				/* sidebar end */
				.page-body{
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
					}
				}
				.bayu-body{
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.1;
				}
				/* body start*/
				.page-header {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}
					.breadcrumb-item {
						&.active {
							color: $dark-small-font-color;
						}
					}
				}
				.page-body {
					background-color: $dark-body-background;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
						.card-header {
							background-color: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							> span {
								color: $dark-small-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-border-color;
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $sidebar-submenu-font-color;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-border-color;
							}
							tbody {
								.border-bottom-primary {
									th, td {
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table-responsive {
							.table {
								th, td {
									border-top: 1px solid $dark-border-color;
								}
							}
						}
						.table[class*='bg-'] {
							th, td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color: rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-border-color;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling {
							thead, tbody {
								th, td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $dark-card-background !important;
						}
						.business-top-widget {
							.media {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.business-card {
							table {
								td {
									color: $dark-small-font-color;
								}
								th {
									color: $dark-all-font-color;
								}
							}
							.logo {
								filter: brightness(1) invert(100%);
							}
						}
						.social-widget {
							.media {
								.social-sub-title {
									color: $dark-small-font-color;
								}
							}
							.total-value {
								color: $dark-all-font-color;
							}
							.social-icons {
								background-color: $dark-card-background;
							}
						}
						.ct-grid {
							stroke: $dark-small-font-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.review-box {
							.testimonial {
								.content {
									border: 1px solid $dark-border-color;
									&:before {
										border-bottom: 10px solid $dark-border-color;
									}
									&:after {
										border-bottom: 9px solid $dark-card-background;
									}
								}
							}
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark, .btn-outline-light-2x{
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.btn-light {
							color: $dark-body-background !important;
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						.crm-top-widget {
							.media {
								.media-body {
									h4 {
										color: $dark-all-font-color;
									}
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
						.stat-widget-dashboard {
							.media {
								img {
									filter: brightness(0) invert(75%);
								}
							}
							span {
								color: $dark-all-font-color;
							}
						}
						.support-ticket {
							table {
								tr {
									& + tr {
										border-top: 1px solid $dark-border-color;
									}
									td {
										p, h6 {
											color: $dark-small-font-color;
										}
										span {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.dataTables_wrapper {
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate {
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: #2e353d;
											}
										}
									}
									tr.odd {
										background-color: $dark-body-background;
										> .sorting_1 {
											background-color: #2e353d;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: rgba(53, 59, 74, 0.30);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th, td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: #2c3239;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: #2e353d;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							&:hover {
								background-color: $dark-border-color;
							}
						}
						.page-item.active {
							.page-link {
								background-color: $primary-color;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.crm-numbers {
							.col {
								+ .col {
									border-left: 1px solid $dark-border-color;
								}
								> span {
									color: $dark-small-font-color;
								}
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-box {
							.product-details {
								h6 {
									color: $dark-all-font-color;
								}
								span {
									color: $dark-small-font-color;
								}
							}
							.product-img {
								.product-hover {
									ul {
										li {
											background-color: $dark-card-background;
											&:hover {
												background-color: $black;
												color: $white;
											}
										}
									}
								}
							}
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span, p {
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6 {
								color: $dark-small-font-color;
							}
						}
						.nav-material.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								&.show {

								}
								.nav-link {
									color: $dark-all-font-color;
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected {
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected {
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget {
								a {
									&:after {
										color: $dark-border-color;
									}
									&.br-selected,
									&.br-active {
										&:after {
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo {
							border: 1px solid $dark-border-color;
						}
						.search-form {
							input {
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								filter: brightness(0) invert(100%);
							}
						}
						.cd-timeline-content {
							background-color: $dark-border-color;
							&::before {
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb {
							background-color: $dark-body-background;
						}
						.user-status {
							table {
								td, th {
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					.customer-review {
						.media-body {
							.cust-name {
								color: $dark-all-font-color;
							}
							.cust-des {
								color: $dark-small-font-color;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: #2e353d;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}

					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator {
							border: 1px solid $dark-border-color;
						}
					}
					.default-according {
						li {
							.text-muted {
								color: $dark-all-font-color !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: $primary-color !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: $dark-all-font-color;
								}
								&:hover {
									color: $primary-color;
									a {
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: $dark-card-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid $dark-card-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-right: 1px solid $dark-border-color;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					#cal-basic {
						.fc-toolbar {
							.fc-left, .fc-right {
								.fc-next-button {
									.fc-icon-right-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
								.fc-prev-button {
									.fc-icon-left-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
								}
								.fc-button-group {
									.fc-month-button {
										color: $white !important;
									}
								}
							}
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					#cal-basic-view {
						.fc-toolbar {
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-left {
								.fc-button-group {
									.fc-agendaWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.fc-button-group {
						.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
						}
					}
					#cal-agenda-view {
						.fc-toolbar {
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-right {
								.fc-button-group {
									.fc-month-button, .btn-light {
										color: $black !important;
									}
									.fc-agendaWeek-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.basic-calendar, #cal-bg-events, #cal-event-colors {
						.fc-toolbar {
							.fc-right {
								.fc-button-group {
									.fc-basicWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					.border-widgets {
						> .row {
							> div {
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
					.whether-widget {
						.whether-widget-top {
							background-color: $primary-color;
							.details {
								h4 {
									color: $white;
								}
							}
						}
						.whether-widget-bottom {
							h5 {
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					.default-widget-count {
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color
						}
						.icon-bg {
							color: #2e3945;
						}
						.left {
							i {
								color: $dark-body-background;
								text-shadow: 1px 1px 1px $dark-card-background;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.testimonial-default {
						.slide--item {
							p {
								color: $dark-all-font-color !important;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}

						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-border-color;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-border-color;
						}
					}
					.page-builder {
						.btn-grid {
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column {
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen {
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}
						.ge-canvas.ge-editing {
							.row {
								background-color: $dark-body-background;
							}
							.column {
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer {
								.ge-details {
									input {
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}
									.btn-group {
										a {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor {
							color: $dark-all-font-color;
						}
					}
					.pricing-wrapper-card {
						.pricing-card {
							background-color: $dark-card-background;
						}
						.card-title {
							h3 {
								color: rgba(206, 212, 218, 0.3);
							}
							h5 {
								color: $dark-all-font-color;
							}
						}
						.card-price {
							h1 {
								small {
									color: $dark-small-font-color;
								}
							}
						}
						.card-description {
							li {
								color: $dark-all-font-color;
								&::before {
									color: rgba(255, 255, 255, 0.15);
								}
							}
						}
					}
					.search-page {
						.info-block {
							+ .info-block {
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute {
						.bg-primary, .bg-secondary {
							h5 {
								color: $white;
							}
						}
					}
					/* body end*/
				}
				.bayu-body {
					background-color: $dark-body-background;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
						.card-header {
							background-color: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							> span {
								color: $dark-small-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-border-color;
								}
								.border-bottom-primary {
									th {
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $sidebar-submenu-font-color;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-border-color;
							}
							tbody {
								.border-bottom-primary {
									th, td {
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table-responsive {
							.table {
								th, td {
									border-top: 1px solid $dark-border-color;
								}
							}
						}
						.table[class*='bg-'] {
							th, td {
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color: rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-border-color;
							}
						}
						.table-inverse {
							tbody {
								tr {
									&:nth-of-type(odd) {
										&:hover {
											td {
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling {
							thead, tbody {
								th, td {
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $dark-card-background !important;
						}
						.business-top-widget {
							.media {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.business-card {
							table {
								td {
									color: $dark-small-font-color;
								}
								th {
									color: $dark-all-font-color;
								}
							}
							.logo {
								filter: brightness(1) invert(100%);
							}
						}
						.social-widget {
							.media {
								.social-sub-title {
									color: $dark-small-font-color;
								}
							}
							.total-value {
								color: $dark-all-font-color;
							}
							.social-icons {
								background-color: $dark-card-background;
							}
						}
						.ct-grid {
							stroke: $dark-small-font-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.review-box {
							.testimonial {
								.content {
									border: 1px solid $dark-border-color;
									&:before {
										border-bottom: 10px solid $dark-border-color;
									}
									&:after {
										border-bottom: 9px solid $dark-card-background;
									}
								}
							}
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark, .btn-outline-light-2x{
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.btn-light {
							color: $dark-body-background !important;
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						.crm-top-widget {
							.media {
								.media-body {
									h4 {
										color: $dark-all-font-color;
									}
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
						.stat-widget-dashboard {
							.media {
								img {
									filter: brightness(0) invert(75%);
								}
							}
							span {
								color: $dark-all-font-color;
							}
						}
						.support-ticket {
							table {
								tr {
									& + tr {
										border-top: 1px solid $dark-border-color;
									}
									td {
										p, h6 {
											color: $dark-small-font-color;
										}
										span {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.dataTables_wrapper {
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate {
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: #2e353d;
											}
										}
									}
									tr.odd {
										background-color: $dark-body-background;
										> .sorting_1 {
											background-color: #2e353d;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: rgba(53, 59, 74, 0.30);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th, td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: #2c3239;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: #2e353d;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							&:hover {
								background-color: $dark-border-color;
							}
						}
						.page-item.active {
							.page-link {
								background-color: $primary-color;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.crm-numbers {
							.col {
								+ .col {
									border-left: 1px solid $dark-border-color;
								}
								> span {
									color: $dark-small-font-color;
								}
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-box {
							.product-details {
								h6 {
									color: $dark-all-font-color;
								}
								span {
									color: $dark-small-font-color;
								}
							}
							.product-img {
								.product-hover {
									ul {
										li {
											background-color: $dark-card-background;
											&:hover {
												background-color: $black;
												color: $white;
											}
										}
									}
								}
							}
						}
						.product-table {
							#API-2_wrapper {
								#API-2 {
									tbody {
										td {
											span, p {
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6 {
								color: $dark-small-font-color;
							}
						}
						.nav-material.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								&.show {

								}
								.nav-link {
									color: $dark-all-font-color;
								}
							}
							.nav-link {
								&.active,
								&:focus,
								&:hover {
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal {
							.br-widget {
								a {
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected {
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget {
								a {
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected {
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget {
								a {
									&:after {
										color: $dark-border-color;
									}
									&.br-selected,
									&.br-active {
										&:after {
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo {
							border: 1px solid $dark-border-color;
						}
						.search-form {
							input {
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								filter: brightness(0) invert(100%);
							}
						}
						.cd-timeline-content {
							background-color: $dark-border-color;
							&::before {
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block {
							&:nth-child(even) {
								.cd-timeline-content {
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb {
							background-color: $dark-body-background;
						}
						.user-status {
							table {
								td, th {
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart {
							svg {
								opacity: 0.5;
							}
						}
					}
					.customer-review {
						.media-body {
							.cust-name {
								color: $dark-all-font-color;
							}
							.cust-des {
								color: $dark-small-font-color;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: #2e353d;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}

					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator {
							border: 1px solid $dark-border-color;
						}
					}
					.default-according {
						li {
							.text-muted {
								color: $dark-all-font-color !important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li {
							button {
								&:hover {
									color: $primary-color !important;
								}
							}
						}
					}
					.nav-list {
						.nav-list-disc {
							li {
								a {
									color: $dark-all-font-color;
								}
								&:hover {
									color: $primary-color;
									a {
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown {
						.onhover-show-div {
							background-color: $dark-card-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
						}
					}
					.onhover-dropdown {
						&:hover {
							.onhover-show-div {
								&:before {
									border-bottom: 7px solid $dark-card-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-right: 1px solid $dark-border-color;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					#cal-basic {
						.fc-toolbar {
							.fc-left, .fc-right {
								.fc-next-button {
									.fc-icon-right-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
								.fc-prev-button {
									.fc-icon-left-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
								}
								.fc-button-group {
									.fc-month-button {
										color: $white !important;
									}
								}
							}
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					#cal-basic-view {
						.fc-toolbar {
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-left {
								.fc-button-group {
									.fc-agendaWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.fc-button-group {
						.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
						}
					}
					#cal-agenda-view {
						.fc-toolbar {
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-right {
								.fc-button-group {
									.fc-month-button, .btn-light {
										color: $black !important;
									}
									.fc-agendaWeek-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.basic-calendar, #cal-bg-events, #cal-event-colors {
						.fc-toolbar {
							.fc-right {
								.fc-button-group {
									.fc-basicWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover {
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					.border-widgets {
						> .row {
							> div {
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
					.whether-widget {
						.whether-widget-top {
							background-color: $primary-color;
							.details {
								h4 {
									color: $white;
								}
							}
						}
						.whether-widget-bottom {
							h5 {
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					.default-widget-count {
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color
						}
						.icon-bg {
							color: #2e3945;
						}
						.left {
							i {
								color: $dark-body-background;
								text-shadow: 1px 1px 1px $dark-card-background;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.testimonial-default {
						.slide--item {
							p {
								color: $dark-all-font-color !important;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}

						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-border-color;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-border-color;
						}
					}
					.page-builder {
						.btn-grid {
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column {
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen {
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}
						.ge-canvas.ge-editing {
							.row {
								background-color: $dark-body-background;
							}
							.column {
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer {
								.ge-details {
									input {
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}
									.btn-group {
										a {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor {
							color: $dark-all-font-color;
						}
					}
					.pricing-wrapper-card {
						.pricing-card {
							background-color: $dark-card-background;
						}
						.card-title {
							h3 {
								color: rgba(206, 212, 218, 0.3);
							}
							h5 {
								color: $dark-all-font-color;
							}
						}
						.card-price {
							h1 {
								small {
									color: $dark-small-font-color;
								}
							}
						}
						.card-description {
							li {
								color: $dark-all-font-color;
								&::before {
									color: rgba(255, 255, 255, 0.15);
								}
							}
						}
					}
					.search-page {
						.info-block {
							+ .info-block {
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute {
						.bg-primary, .bg-secondary {
							h5 {
								color: $white;
							}
						}
					}
					/* body end*/
				}
				footer {
					border-top: 1px solid $dark-body-background;
					background-color: $dark-card-background;
				}
				.custom-select, .custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}
				.footer-fix {
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}
			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;
				h2 {
					background-color: $dark-card-background;
				}
			}
			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}
				.chat-menu {
					border-left: 1px solid $dark-border-color;
					.nav-tabs {
						border-bottom: 1px solid $dark-border-color;
						.nav-item {
							.nav-link {
								&.active {
									color: $dark-all-font-color !important;
								}
							}
						}
					}

					.user-profile {
						.image {
							.icon-wrapper {
								background-color: $dark-card-background;
								box-shadow: 1px 1px 3px 1px $dark-body-background;
							}
							.avatar {
								img {
									border: 5px solid $dark-border-color;
								}
							}
						}
						.social-media {
							a {
								color: $dark-small-font-color;
							}
						}
						.follow {
							span {
								color: $dark-small-font-color;
							}
							.follow-num {
								color: $dark-all-font-color;
							}
						}
					}
				}
				.status {
					color: $dark-small-font-color;
					p {
						color: $dark-all-font-color !important;
					}
				}
				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid $dark-border-color;
							.chat-menu-icons {
								li {
									a {
										i {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.chat-msg-box {
							.my-message {
								border: 1px solid $dark-border-color
							}
							.message {
								color: $dark-all-font-color;
							}
							.other-message {
								background-color: $dark-body-background;
							}
						}
						.chat-message {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
							.input-group {
								.form-control {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.chat-history {
					.call-icons {
						ul {
							li {
								border: 1px solid $dark-border-color;
								a {
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
			}
			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}
			.scorlled {
				background-color: $dark-body-background;
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}
			.input-group-solid {
				.input-group-text, .form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
			.semilight-bg-color, .header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: $dark-all-font-color;
				&.active {
					background-color: $primary-color;
					border-color: $primary-color;
					color: $white;
				}
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
								}
								label {
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									color: $dark-all-font-color;
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
					h3, h4, h6 {
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4, h3 {
						color: $white;
					}
					h6 {
						color: $dark-small-font-color;
					}
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input[type=text],
								input[type=password] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}
						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}
			.modal-content {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.2);
				.modal-header {
					border-bottom: 1px solid $dark-border-color;
					.close {
						color: $dark-small-font-color;
					}
				}
				.modal-footer {
					border-top: 1px solid $dark-border-color;
				}
			}
			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.8);
				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}
					.countdown {
						border-top: 1px solid $dark-border-color;
						border-bottom: 1px solid $dark-border-color;
						.title {
							color: $dark-all-font-color;
						}
					}
					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
					img {
						filter: brightness(1) invert(100%);
					}
				}
			}
			.authentication-main {
				.auth-innerleft {
					background: url(/assets/images/auth-layer.png), rgba(54, 64, 74, 0.9);
					background-blend-mode: overlay;
					.text-center {
						img {
							filter: brightness(1) invert(100%);
						}
					}
					.social-media {
						li {
							color: $dark-body-background;
						}
					}
				}
				.auth-innerright {
					background-color: $dark-border-color;
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}
						.reset-password-link {
							color: $dark-small-font-color;
						}
						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}
								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-border-color;
								}
							}
							.opt-box {
								background-color: $dark-border-color;
							}
						}

					}
					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-small-font-color;
						}
						h3 {
							color: $dark-all-font-color;
						}
						.card {
							background-color: $dark-card-background;
							.theme-form {
								.form-group {
									input[type=text],
									input[type=password] {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
										color: $dark-all-font-color;
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-border-color;
										}
									}
								}
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: $dark-card-background;
					.btn-link {
						background-color: $dark-card-background;
						border: 1px solid $dark-card-background;
						color: $white;
					}
					.text-muted {
						color: $dark-small-font-color !important;
					}
				}
				.bg-primary {
					.btn-link {
						background-color: $primary-color;
						border: 1px solid $primary-color;
					}
				}
				.bg-secondary {
					.btn-link {
						background-color: $secondary-color;
						border: 1px solid $secondary-color;
					}
				}
			}
			.collapse {
				.card-body {
					background-color: $dark-card-background;
				}
			}
			@media screen and (max-width: 1660px) {
				.chat-box {
					.chat-history {
						.call-icons {
							ul {
								li {
									border: 1px solid $dark-border-color;
								}
							}
						}
						.total-time {
							h2 {
								color: $dark-all-font-color;
							}
						}
					}
				}
				.caller-img {
					img {
						opacity: 0.7;
					}
				}
				.chat-box {
					.chat-history {
						.call-content {
							> div {
								background-color: rgba(0, 0, 0, 0.75);
								background-blend-mode: overlay;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1366px) {
				//crm dashboard
				.crm-numbers {
					.col {
						border-bottom: 1px solid $dark-border-color;
						+ .col {
							border-left: 1px solid $dark-border-color;
							& + .col {
								border-left: 1px solid $dark-border-color;
								& + .col {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1199px) {
				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}
				.error-wrapper {
					.maintenance-icons {
						li {
							i {
								color: $dark-color;
							}
						}
					}
				}
				.border-widgets {
					> .row {
						> div:nth-child(1) {
							border-bottom: 1px solid $dark-border-color;
						}
						> div:nth-child(2) {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
				.crm-numbers {
					.col {
						+ .col {
							border-left: 1px solid $dark-border-color;
							& + .col {
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
			}

			@media only screen and (max-width: 991px) {
				.crm-numbers {
					.col {
						+ .col {
							border-left: 1px solid $dark-border-color;
						}
					}
				}
			}
			@media only screen and (max-width: 767px) {
				//crm dashboard
				.border-widgets {
					> .row {
						> div {
							+ div {
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 575px) {
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid $dark-border-color;
								border-bottom: 1px solid $dark-border-color;
							}
						}
					}
				}
				.border-widgets {
					> .row {
						> div {
							+ div {
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
				.widget-joins {
					.border-after-xs {
						&:after {

							background-color: $dark-border-color;
						}
					}
				}
			}
			@media only screen and (max-width: 480px) {
				.business-card {
					.b-l-light {
						border-left: 1px solid $dark-border-color !important;
					}
				}
			}
			@media only screen and (max-width: 360px) {
				.border-widgets {
					> .row {
						> div {
							& + div {
								& + div {
									border-bottom: 1px solid $dark-border-color;
								}
							}
						}
					}
				}
			}
		}
		.lg-backdrop {
			background-color: $dark-body-background;
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}
		.drag {
			background-color: $dark-card-background;
			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;
				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}
		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			p {
				color: $dark-small-font-color;
			}
			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			.radio, .checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}
		.select2-container--default {
			.select2-selection--multiple, .select2-selection--single {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color !important;
			}
			.select2-search--inline {
				.select2-search__field {
					color: $dark-all-font-color;
				}
			}
			.select2-selection--single {
				.select2-selection__rendered {
					color: $dark-all-font-color;
				}
			}
			.select2-search--dropdown {
				.select2-search__field {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
		}
		.select2-dropdown {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: $primary-color !important;
				color: $primary-color !important;
			}
			.form-control-secondary {
				border-color: $secondary-color !important;
				color: $secondary-color !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill {
				background-color: $primary-color !important;
				color: $white !important;
			}
			.form-control-secondary-fill {
				background-color: $secondary-color !important;
				color: $white !important;
			}
			.form-control-success-fill {
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill {
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill {
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill {
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill {
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled {
			.select2-selection--single {
				background-color: $dark-border-color;
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color;
				}
				input[type=text], input[type=email], input[type=search],
				input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local], input[type=time], input[type=datetime-local], input[type=month], input[type=week],
				input[type=url], input[type=file], select {
					border-color: $dark-border-color;
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}
				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
				textarea {
					border-color: $dark-border-color;
				}
			}
			.form-divider {
				border-top: 1px solid $dark-border-color;
				&::before {
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.CodeMirror {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;
			.CodeMirror-code {
				pre {
					background: $dark-card-background;
				}
			}
		}
		.editor-toolbar {
			border-top: 1px solid $dark-small-font-color;
			border-left: 1px solid $dark-small-font-color;
			border-right: 1px solid $dark-small-font-color;
			a {
				color: $dark-all-font-color !important;
				&:hover,
				&.active {
					background: $dark-border-color;
				}
			}
			i.separator {
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}
		}
		.editor-toolbar.disabled-for-preview {
			a {
				&:not(.no-disable) {
					background: $dark-border-color;
				}
			}
		}
		.editor-preview {
			background-color: $dark-card-background;
		}
		.editor-toolbar.fullscreen, .editor-preview-side {
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}
		.u-step {
			background: $dark-border-color;
			&.active,
			&.current {
				background: $primary-color;
				color: $white;
			}
		}
		.u-step-title, .u-pearl-title {
			color: $dark-all-font-color;
		}
		.u-step-number {
			background-color: $dark-card-background;
		}
		.u-pearl {
			&:before {
				background-color: $dark-border-color;
			}
		}
		.u-pearl-number, .u-pearl-icon {
			background: $dark-body-background;
			border: 2px solid $dark-body-background;
		}
		.u-pearl.disabled {
			.u-pearl-icon, .u-pearl-number {
				background: $dark-border-color;
				border: 2px solid $dark-border-color;
			}
			&:after {
				background-color: #2e353d;
			}
		}
		.u-pearl.error {
			&:after {
				background-color: $dark-body-background;
			}
		}
		.note-editor.note-frame {
			border-color: $dark-border-color;
			.note-editing-area {
				.note-editable {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.swal-modal {
			background-color: $dark-card-background;
			.swal-title {
				color: $dark-all-font-color;
			}
			.swal-text {
				color: $dark-small-font-color;
			}
			.swal-content__input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}
		.nav-tabs {
			border-bottom: 1px solid $dark-border-color;
			.nav-link {
				&.active {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
				&:hover,
				&:focus {
					border-color: $dark-border-color $dark-border-color $dark-border-color;
				}
			}
			.nav-item.show {
				.nav-link {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-small-font-color $dark-border-color $dark-card-background;
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link {
					&.active,
					&.focus,
					&.hover {
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}
		.nav-material.nav-left, .nav-material.nav-right {
			.nav-link {
				color: $dark-all-font-color;
				&.active {
					color: $primary-color;
				}
			}
			.show {
				> .nav-link {
					color: $primary-color;
				}
			}
		}
		.nav-material.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					border-left-color: $secondary-color;
					color: $secondary-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $secondary-color;
				color: $secondary-color !important;
			}
			.nav-item.show {
				color: $secondary-color !important;
				border-left-color: $secondary-color;
			}
		}
		.nav-material.nav-left.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show {
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.nav-material.nav-right.nav-info {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show {
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.nav-material.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $secondary-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $secondary-color !important;
				}
			}
		}
		.nav-material.nav-success {
			.nav-link {
				&.active,
				&:focus,
				&:hover {
					color: $success-color !important;
				}
			}
			.nav-item {
				&.show {
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider {
			border-top: 1px solid $dark-border-color;
		}
		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-border-color;
			.icon-title {
				color: $dark-all-font-color;
			}
			span {
				color: $dark-small-font-color;
			}
			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}
		code {
			background-color: #434f5a;
		}
		#cd-timeline {
			&::before {
				background: $dark-border-color;
			}
		}
		.timeliny {
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
			&::before {
				background-color: $white;
			}
			.timeliny-dot {
				background-color: $dark-card-background;
				border: 1px solid $white;
				&::before {
					color: $dark-all-font-color;
				}
			}
			.timeliny-timeline {
				.timeliny-timeblock {
					&.active {
						.timeliny-dot {
							&::before {
								color: $dark-all-font-color;
							}
						}
					}
					&:not(.inactive):not(.active) {
						.timeliny-dot {
							&:hover {
								background-color: $white;
								&::before {
									color: $dark-all-font-color;
								}
							}
						}
					}
					.timeliny-dot {
						&:hover {
							&::after {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.todo {
			.todo-list-wrapper {
				#todo-list {
					li {
						.task-container {
							background: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							.task-label {
								color: $dark-all-font-color;
							}
							&:hover {
								h4 {
									color: $white;
								}
							}
							.task-action-btn {
								.action-box {
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
						&.completed {
							.task-container {
								.task-label {
									color: $primary-color;
								}
								.complete-btn {
									&:hover {
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper {
						textarea {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							color: $dark-all-font-color;
						}
					}
				}

			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: $dark-small-font-color;
				}
				span {
					color: $dark-all-font-color;
				}
			}
			.hovercard {
				.info {
					.title {
						a {
							color: $dark-all-font-color;
						}
					}
				}
				.user-image {
					.avatar {
						img {
							border: 10px solid $dark-card-background;
						}
					}
					.icon-wrapper {
						background-color: $dark-card-background;
					}
				}
			}
			.follow {
				.follow-num {
					color: $dark-all-font-color;
				}
			}
			.profile-img-style {
				.user-name {
					color: $dark-all-font-color;
				}
				p {
					color: $dark-small-font-color;
				}
			}
		}
		.introjs-helperLayer {
			background-color: rgba(54, 64, 74, 0.9);
			border: rgba(255, 255, 255, 0.5);
		}
		.jstree-default {
			.jstree-node, .jstree-icon {
				color: #434f5a !important;
			}
			.jstree-anchor {
				color: $dark-small-font-color;
			}
			.jstree-clicked {
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
			}
		}
		span.twitter-typeahead {
			.tt-menu {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
			.tt-suggestion {
				color: $dark-all-font-color;
				&:hover,
				&:focus {
					background-color: $dark-card-background;
				}
			}
		}
		.typography {
			small {
				color: $dark-all-font-color;
			}
		}
		.blockquote-footer {
			color: $dark-small-font-color;
		}
		.quickview-wrapper {
			background-color: $dark-card-background;
			box-shadow: 0 0 9px $dark-border-color;
			border-left: 1px solid $dark-border-color;
			.quickview-box, .quickview-recent-activity {
				&:before {
					background-color: $dark-card-background;
					box-shadow: 0 0 9px $dark-border-color;
					border-left: 1px solid $dark-border-color;
				}
				&:after {
					background-color: $dark-card-background;
				}
			}
			.nav-material.nav-tabs {
				.nav-item {
					.nav-link {
						color: $dark-all-font-color;
					}
				}
			}
			.nav-material.nav-primary {
				.nav-link {
					&.active, :hover {
						color: $primary-color;
						background-color: transparent;
					}
				}
				.nav-item.show, .nav-link:focus {
					color: $primary-color;
					background-color: transparent;
				}
			}
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
				.modal-title {
					color: $dark-all-font-color;
				}
				i {
					color: $dark-small-font-color;
				}
			}
			.friend-list-search {
				background-color: #2f3841;
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.recent-activity {
				.activity-content {
					h6 {
						color: $dark-all-font-color;
					}
					hr {
						border-top: 1px solid $dark-border-color;
					}
				}
				.user-first-letter {
					&:after {
						border: 1px dashed $dark-border-color;
					}
				}
			}
			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}
			}
		}
		.code-box-copy {
			pre {
				background-color: $dark-body-background;
				code {
					background-color: $dark-body-background;
				}
			}
			pre[class*="language-"] {
				border: 1px solid $dark-border-color;
			}
			.code-box-copy__btn {
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color: $white;
			}
			code[class*=language-], pre[class*=language-] {
				text-shadow: 0px 1px $black;
				::selection{
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}
		table.fixedHeader-floating {
			background-color: $dark-body-background;
		}
		.dt-button-info {
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;
			h2 {
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}
		#keytable_wrapper, #column-selector_wrapper, #auto-fill_wrapper, #custom-button_wrapper, #class-button_wrapper, #keyboard-btn_wrapper, #multilevel-btn_wrapper, #pagelength-btn_wrapper, #basic-colreorder_wrapper, #state-saving_wrapper, #real-time_wrapper, #basic-fixed-header_wrapper, #fixed-header-footer_wrapper, #export-button_wrapper, #excel-cust-bolder_wrapper, #cust-json_wrapper, #basic-key-table_wrapper, #focus-cell_wrapper, #responsive_wrapper, #new-cons_wrapper, #show-hidden-row_wrapper, #basic-row-reorder_wrapper, #full-row_wrapper, #rest-column_wrapper {
			.dataTables_paginate {
				border: none;
			}
		}
		#example-style-8_wrapper {
			table.dataTable.stripe, table.dataTable.display {
				tbody {
					tr.odd {
						background-color: $dark-border-color;
					}
				}
			}
		}
		.error-wrapper {
			background-color: rgba(54, 64, 74, 0.7);
			.sub-content {
				color: $dark-all-font-color;
			}
		}
		.b-light {
			border: 1px solid $dark-border-color !important;
		}
		.modal-content {
			background-color: $dark-card-background;
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
			}
			.ui-front {
				.form-control {
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
		}
		.stepwizard {
			.stepwizard-row {
				&:before {
					background-color: $dark-small-font-color;
				}
			}
		}
		.modal {
			.theme-close {
				background-color: $dark-card-background !important;
			}
		}
		.token.atrule, .token.attr-value, .token.keyword {
			color: $info-color;
		}
		.token{
			&.boolean, &.constant, &.deleted, &.number, &.property, &.symbol, &.tag{
				color: $danger-color;
			}
		}
		.loader-box{
			&.card-loader{
				background-color: $dark-card-background;
			}
		}
		.my-gallery{
			&.gallery-with-description{
				img {
					border: 1px solid $dark-border-color !important;
					border-bottom: none !important;
				}
			}
		}

	}
}
// ========= theme layout 3  scss end ==========//

// ========= theme layout 4  scss start ==========//
body {
	&[main-theme-layout="main-theme-layout-4"] {
		color: $dark-all-font-color;
		background-color: $dark-body-background;
		.styles_modal__gNwvD {
			background-color: $dark-card-background;
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
			}
			.modal-footer {
				border-top: 1px solid $dark-border-color;
			}
		}
		.grid-showcase {
			span {
				border: 1px solid $dark-border-color;
				background-color: $dark-card-background;
			}
		}
		.form-control {
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
			color: $dark-small-font-color;
			&::placeholder {
				color: $dark-small-font-color;
			}
		}
		.dropdown-header {
			color: $dark-all-font-color;
		}
		.dropdown-item, span.twitter-typeahead .tt-suggestion {
			color: $dark-small-font-color;
		}
		.h-auto, .sweet-alert {
			background-color: $dark-card-background !important;
		}
		.datatable-react {
			.react-bootstrap-table {
				.table-bordered {
					td, th {
						border-color: $dark-border-color;
					}
				}
				table {
					tbody {
						tr {
							&:nth-child(odd) {
								background-color: #303841;
								td {
									&:first-child {
										background-color: #2e353d;
									}
								}
								&:hover {
									td {
										background-color: #2e353d;
									}
								}
							}
							&:nth-child(even) {
								td {
									&:first-child {
										background-color: rgba(53, 59, 74, 0.3);
									}
								}
								&:hover {
									td {
										background-color: #2e353d;
										&:first-child {
											background-color: #2c3239;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.todo {
			.task-container {
				.table-responsive {
					table {
						td {
							border-top: none !important;
						}
					}
				}
			}
		}
		.todo-options {
			ul {
				li {
					a {
						color: $dark-all-font-color;
						&:hover {
							color: $primary-color;
						}
					}
				}
			}
		}
		.email-wrap {
			.email-right-aside {
				background-color: $dark-card-background;
			}
		}
		.product-table {
			table {
				border: 1px solid $dark-border-color;
			}
		}
		.flot-chart-container{
			.legend{
				.table{
					tbody{
						background-color: $dark-card-background;
						color: $white;
						.legendLabel{
							padding-left: 5px;
						}
					}
				}
			}
		}
		.google-visualization-tooltip{
			text{
				fill: rgba(0, 0, 0, 0.7) !important;
			}
		}
		.nav-dark ,.nav-pills.nav-dark{
			.nav-link{
				color: $white;
			}
		}
		.loader-wrapper {
			background-color: $dark-body-background ;
			.loader {
				background-color: $dark-body-background !important;
			}
		}
		.page-wrapper {
			/* Main Header start */
			.page-main-header {
				background-color: $dark-card-background;
				box-shadow: 0px 0px 1px 1px $dark-all-font-color;
				.main-header-right {
					background-color: $dark-card-background;
					li {
						i {
							color: $light-color;
						}
					}
					.nav-right {
						.nav-menus {
							li {
								.form-inline {
									.form-control-plaintext {
										filter: brightness(0) invert(100%);
									}
								}
								a {
									img {
										filter: brightness(0) invert(100%);
									}
								}
								.media {
									img {
										filter: brightness(0) invert(100%);
									}
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid $dark-border-color;
										span {
											color: $dark-small-font-color;
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;
								}
							}
						}
						.search-form {
							input {
								filter: brightness(0) invert(100%);
							}
						}
						> ul {
							> li {
								a {
									img {
										filter: brightness(0) invert(100%);
									}
								}
								.media {
									img {
										filter: brightness(0) invert(100%);
									}
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid $dark-border-color;
										span {
											color: $dark-small-font-color;
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
					.mega-menu-header{
						.vertical-menu-main{
							.main-nav{
								.main-menu{
									li{
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
				}
				.main-header-left {
					background-color: $dark-card-background;
					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}
							.image-light{
								display: block;
							}
						}
					}
				}
				@media only screen and (max-width: 991px){
					.main-header-right{
						.nav-right {
							> ul{
								background-color: $dark-card-background;
								box-shadow: 0 2px 2px 2px $dark-border-color;
							}
						}
					}
				}
				@media only screen and (max-width: 575px){
					.nav-right{
						> ul {
							.search-form {
								.mobile-search {
									filter: brightness(1) invert(100%);
								}
								.form-control-plaintext{
									background-color: $dark-body-background;
									filter: brightness(1) invert(9%) !important;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			.bayu-main-header {
				background-color: $dark-card-background;
				box-shadow: 0px 0px 1px 1px $dark-all-font-color;
				.bayu-header-right {
					background-color: $dark-card-background;
					li {
						i {
							color: $light-color;
						}
					}
					.nav-right {
						.nav-menus {
							li {
								.form-inline {
									.form-control-plaintext {
										filter: brightness(0) invert(100%);
									}
								}
								a {
									img {
										filter: brightness(0) invert(100%);
									}
								}
								.media {
									img {
										filter: brightness(0) invert(100%);
									}
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid $dark-border-color;
										span {
											color: $dark-small-font-color;
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.profile-dropdown {
							li {
								a {
									color: $dark-all-font-color;
								}
							}
						}
						.search-form {
							input {
								filter: brightness(0) invert(100%);
							}
						}
						> ul {
							> li {
								a {
									img {
										filter: brightness(0) invert(100%);
									}
								}
								.media {
									img {
										filter: brightness(0) invert(100%);
									}
									.media-body {
										.txt-dark {
											color: $dark-all-font-color !important;
										}
									}
								}
								.onhover-show-div {
									background-color: $dark-body-background;
									box-shadow: 0 0 2px 2px $dark-card-background;
									&:before {
										border-bottom: 7px solid $dark-body-background;
									}
									&:after {
										border-bottom: 7px solid $dark-body-background;
									}
								}
								.notification-dropdown.onhover-show-div {
									li {
										border-bottom: 1px solid $dark-border-color;
										span {
											color: $dark-small-font-color;
										}
									}
								}
								.language-dropdown {
									li {
										a {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
					}
					.mega-menu-header{
						.vertical-menu-main{
							.main-nav{
								.main-menu{
									li{
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
				}
				.bayu-header-left {
					background-color: $dark-card-background;
					.logo-wrapper {
						a {
							.image-dark {
								display: none;
							}
							.image-light{
								display: block;
							}
						}
					}
				}
				@media only screen and (max-width: 991px){
					.bayu-header-right{
						.nav-right {
							> ul{
								background-color: $dark-card-background;
								box-shadow: 0 2px 2px 2px $dark-border-color;
							}
						}
					}
				}
				@media only screen and (max-width: 575px){
					.nav-right{
						> ul {
							.search-form {
								.mobile-search {
									filter: brightness(1) invert(100%);
								}
								.form-control-plaintext{
									background-color: $dark-body-background;
									filter: brightness(1) invert(9%) !important;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
					}
				}
			}
			/* Main Header ends */
			.page-body-wrapper {
				/* sidebar start */
				&.sidebar-icon {
					.page-sidebar{
						.sidebar-menu {
							.sidebar-header{
								> i {
									color: $dark-all-font-color;
								}
							}
							li{
								background-color: $dark-card-background;
								&:hover{
									.single-header,
									.sidebar-submenu{
										background-color: $dark-card-background;
									}
								}
								a:hover + ul{
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.page-body{
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
					}
				}
				.bayu-body{
					.card.custom-card {
						.card-header{
							border-bottom : none !important;
						}
					}
				}
				.custom-card .card-header img {
					background-color: $black;
					opacity: 0.1;
				}
				.page-sidebar {
					&.native-image-bg {
						background-blend-mode: overlay;
						background-color: #17191d;
					}
					&.navigation-bordered {
						.sidebar-header {
							border-bottom: 1px solid rgba(241, 241, 241, 0.15);
						}
					}
					background: $dark-card-background;
					background-blend-mode: overlay;
					.sidebar-user {
						background-color: $dark-card-background;
						box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
						h6 {
							color: $dark-all-font-color;
						}
						p {
							color: $white;
						}
					}
					.sidebar-menu {
						.sidebar-title {
							color: $primary-color;
							border-bottom: 1px solid rgba(241, 241, 241, 0.35);
						}
						>
						li {
							> a {
								color: $dark-all-font-color;
								&.active {
									color: $primary-color;
								}
								&:hover {
									color: $primary-color;
								}
							}
							.label {
								margin-top: 3px;
								margin-right: 5px;
							}
							&:hover > a {
								@extend %common;
								color: $dark-all-font-color;
							}
						}
						li {
							&.sidebar-header {
								color: $warning-color;
							}

						}
						a {
							color: $light-color;
						}
						.sidebar-submenu {
							> li {
								> a {
									color: $sidebar-submenu-font-color;
									&:hover {
										color: $primary-color;
									}
									&.active {
										color: $primary-color;
									}
								}
							}
						}
					}
					.sidebar-widget {
						border-top: 1px solid $dark-border-color;
						.sidebar-widget-top {
							i {
								background-color: $dark-card-background;
							}
						}
						.sidebar-widget-bottom {
							background-color: $dark-body-background;
						}
					}
				}
				/* sidebar end */

				/* body start*/
				.page-header {
					.row {
						h3 {
							small {
								color: $dark-small-font-color;
							}
						}
					}
					.breadcrumb-item {
						&.active {
							color: $dark-small-font-color;
						}
					}
				}
				.page-body {
					background-color: $dark-body-background;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
						.card-header {
							background-color: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							> span {
								color: $dark-small-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-border-color;
								}
								.border-bottom-primary {
									th{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $sidebar-submenu-font-color;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-border-color;
							}
							tbody {
								.border-bottom-primary{
									th, td{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table-responsive{
							.table{
								th,td{
									border-top: 1px solid $dark-border-color;
								}
							}
						}
						.table[class*='bg-']{
							th, td{
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color:rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-border-color;
							}
						}
						.table-inverse{
							tbody {
								tr{
									&:nth-of-type(odd){
										&:hover {
											td{
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling{
							thead, tbody{
								th, td{
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $dark-card-background !important;
						}
						.business-top-widget {
							.media {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.business-card {
							table {
								td {
									color: $dark-small-font-color;
								}
								th {
									color: $dark-all-font-color;
								}
							}
							.logo{
								filter: brightness(1) invert(100%);
							}
						}
						.social-widget {
							.media {
								.social-sub-title {
									color: $dark-small-font-color;
								}
							}
							.total-value {
								color: $dark-all-font-color;
							}
							.social-icons {
								background-color: $dark-card-background;
							}
						}
						.ct-grid {
							stroke: $dark-small-font-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.review-box {
							.testimonial {
								.content {
									border: 1px solid $dark-border-color;
									&:before {
										border-bottom: 10px solid $dark-border-color;
									}
									&:after {
										border-bottom: 9px solid $dark-card-background;
									}
								}
							}
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark, .btn-outline-light-2x {
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.btn-light {
							color: $dark-body-background !important;
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						.crm-top-widget {
							.media {
								.media-body {
									h4 {
										color: $dark-all-font-color;
									}
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
						.stat-widget-dashboard {
							.media {
								img {
									filter: brightness(0) invert(75%);
								}
							}
							span {
								color: $dark-all-font-color;
							}
						}
						.support-ticket {
							table {
								tr {
									& + tr {
										border-top: 1px solid $dark-border-color;
									}
									td {
										p, h6 {
											color: $dark-small-font-color;
										}
										span {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.dataTables_wrapper {
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate{
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: #2e353d;
											}
										}
									}
									tr.odd {
										background-color: $dark-body-background;
										> .sorting_1 {
											background-color: #2e353d;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: rgba(53, 59, 74, 0.30);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th, td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: #2c3239;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: #2e353d;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							&:hover{
								background-color: $dark-border-color;
							}
						}
						.page-item.active {
							.page-link {
								background-color: $primary-color;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.crm-numbers {
							.col {
								+ .col {
									border-left: 1px solid $dark-border-color;
								}
								> span {
									color: $dark-small-font-color;
								}
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-box {
							.product-details {
								h6 {
									color: $dark-all-font-color;
								}
								span {
									color: $dark-small-font-color;
								}
							}
							.product-img {
								.product-hover {
									ul {
										li{
											background-color: $dark-card-background;
											&:hover{
												background-color: $black;
												color: $white;
											}
										}
									}
								}
							}
						}
						.product-table{
							#API-2_wrapper{
								#API-2{
									tbody{
										td{
											span, p{
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6{
								color: $dark-small-font-color;
							}
						}
						.nav-material.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								&.show{

								}
								.nav-link{
									color: $dark-all-font-color;
								}
							}
							.nav-link{
								&.active,
								&:focus,
								&:hover{
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal{
							.br-widget{
								a{
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected{
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget{
								a{
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background ;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected{
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget{
								a{
									&:after{
										color: $dark-border-color;
									}
									&.br-selected,
									&.br-active{
										&:after{
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo{
							border: 1px solid $dark-border-color;
						}
						.search-form{
							input{
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								filter: brightness(0) invert(100%);
							}
						}
						.cd-timeline-content{
							background-color: $dark-border-color;
							&::before{
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block{
							&:nth-child(even) {
								.cd-timeline-content{
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb{
							background-color: $dark-body-background;
						}
						.user-status{
							table{
								td, th{
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart{
							svg{
								opacity: 0.5;
							}
						}
					}
					.customer-review {
						.media-body {
							.cust-name {
								color: $dark-all-font-color;
							}
							.cust-des {
								color: $dark-small-font-color;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: #2e353d;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}

					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator{
							border: 1px solid $dark-border-color;
						}
					}
					.default-according{
						li{
							.text-muted{
								color: $dark-all-font-color!important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li{
							button{
								&:hover
								{
									color: $primary-color!important;
								}
							}
						}
					}
					.nav-list{
						.nav-list-disc{
							li{
								a{
									color: $dark-all-font-color;
								}
								&:hover{
									color: $primary-color;
									a{
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown{
						.onhover-show-div{
							background-color: $dark-card-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
						}
					}
					.onhover-dropdown{
						&:hover {
							.onhover-show-div{
								&:before{
									border-bottom: 7px solid $dark-card-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-right: 1px solid $dark-border-color;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}
						hr{
							border-top: 1px solid $dark-border-color;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					#cal-basic {
						.fc-toolbar {
							.fc-left, .fc-right {
								.fc-next-button {
									.fc-icon-right-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
								.fc-prev-button {
									.fc-icon-left-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
								}
								.fc-button-group{
									.fc-month-button{
										color: $white !important;
									}
								}
							}
							.fc-right{
								.btn-light{
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					#cal-basic-view{
						.fc-toolbar {
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-left {
								.fc-button-group {
									.fc-agendaWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.fc-button-group {
						.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
						}
					}
					#cal-agenda-view {
						.fc-toolbar {
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-right {
								.fc-button-group {
									.fc-month-button, .btn-light {
										color: $black !important;
									}
									.fc-agendaWeek-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.basic-calendar, #cal-bg-events, #cal-event-colors {
						.fc-toolbar {
							.fc-right {
								.fc-button-group {
									.fc-basicWeek-button , .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					.border-widgets {
						> .row {
							> div {
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
					.whether-widget {
						.whether-widget-top {
							background-color: $primary-color;
							.details {
								h4 {
									color: $white;
								}
							}
						}
						.whether-widget-bottom {
							h5 {
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					.default-widget-count {
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color
						}
						.icon-bg {
							color: #2e3945;
						}
						.left {
							i {
								color: $dark-body-background;
								text-shadow: 1px 1px 1px $dark-card-background;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.testimonial-default {
						.slide--item {
							p {
								color: $dark-all-font-color !important;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}

						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-border-color;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-border-color;
						}
					}
					.page-builder {
						.btn-grid{
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column{
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen{
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}
						.ge-canvas.ge-editing {
							.row{
								background-color: $dark-body-background;
							}
							.column{
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer{
								.ge-details{
									input{
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}
									.btn-group{
										a{
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor{
							color: $dark-all-font-color;
						}
					}
					.pricing-wrapper-card{
						.pricing-card{
							background-color: $dark-card-background;
						}
						.card-title{
							h3{
								color: rgba(206, 212, 218, 0.3);
							}
							h5{
								color: $dark-all-font-color;
							}
						}
						.card-price {
							h1{
								small{
									color: $dark-small-font-color;
								}
							}
						}
						.card-description{
							li{
								color: $dark-all-font-color;
								&::before {
									color: rgba(255,255,255,0.15);
								}
							}
						}
					}
					.search-page {
						.info-block{
							+ .info-block{
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute{
						.bg-primary, .bg-secondary{
							h5{
								color: $white;
							}
						}
					}
					/* body end*/
				}
				.bayu-body {
					background-color: $dark-body-background;
					.card {
						background-color: $dark-card-background;
						box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
						.card-header {
							background-color: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							> span {
								color: $dark-small-font-color;
							}
							h1, h2, h3, h4, h5, h6 {
								color: $dark-all-font-color;
							}
							.card-header-right {
								i {
									color: $dark-all-font-color;
								}
							}
						}
						.alert-dark {
							color: $dark-small-font-color;
							a {
								color: $dark-small-font-color;
							}
						}
						.alert-light.outline, .alert-light.outline-2x, .alert-light.inverse {
							color: $dark-all-font-color;
						}
						#animation-box {
							.animate-widget {
								p {
									color: $dark-small-font-color !important;
								}
							}
						}
						.grid-item {
							img {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.line {
							color: $dark-all-font-color;
						}
						.table {
							th, td {
								color: $dark-all-font-color;
							}
							thead {
								th {
									border-bottom: 2px solid $dark-border-color;
								}
								.border-bottom-primary {
									th{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
							.table-primary, .table-secondary, .table-success, .table-info, .table-warning, .table-light, .table-danger {
								th, td {
									color: $sidebar-submenu-font-color;
								}
							}
							.bg-primary, .bg-secondary, .bg-success, .bg-info, .bg-warning, .bg-danger {
								color: $white;
							}
							.bg-light {
								color: $black;
							}
							.thead-light {
								th {
									color: $black;
								}
							}
							.double, .dotted, .dashed {
								border-color: $dark-border-color;
							}
							tbody {
								.border-bottom-primary{
									th, td{
										border-bottom: 1px solid $primary-color;
									}
								}
							}
						}
						.table-responsive{
							.table{
								th,td{
									border-top: 1px solid $dark-border-color;
								}
							}
						}
						.table[class*='bg-']{
							th, td{
								color: $white;
							}
						}
						.table-striped {
							tbody {
								tr {
									&:nth-of-type(odd) {
										background-color:rgba(0, 0, 0, 0.05);
										&:hover {
											th, td {
												color: $white;
											}
										}
									}
								}
							}
						}
						.table-bordered {
							td, th {
								border-color: $dark-border-color;
							}
						}
						.table-inverse{
							tbody {
								tr{
									&:nth-of-type(odd){
										&:hover {
											td{
												color: $dark-all-font-color;
											}
										}
									}
								}
							}
						}
						.table-border-vertical {
							tr, th, td {
								border-right: 1px solid $dark-border-color;
							}
						}
						.table-styling{
							thead, tbody{
								th, td{
									color: $white;
								}
							}
						}
						.card-footer {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.switch {
							.switch-state {
								background-color: $dark-body-background;
							}
							input {
								&:checked {
									+ .switch-state {
										background-color: $primary-color;
									}
								}
							}
						}
						.bg-white {
							background-color: $dark-card-background !important;
						}
						.business-top-widget {
							.media {
								span {
									color: $dark-small-font-color;
								}
							}
						}
						.b-l-light {
							border-left: 1px solid $dark-border-color !important;
						}
						.business-card {
							table {
								td {
									color: $dark-small-font-color;
								}
								th {
									color: $dark-all-font-color;
								}
							}
							.logo{
								filter: brightness(1) invert(100%);
							}
						}
						.social-widget {
							.media {
								.social-sub-title {
									color: $dark-small-font-color;
								}
							}
							.total-value {
								color: $dark-all-font-color;
							}
							.social-icons {
								background-color: $dark-card-background;
							}
						}
						.ct-grid {
							stroke: $dark-small-font-color;
						}
						.ct-label {
							color: $dark-small-font-color;
						}
						hr {
							border-top: 1px solid $dark-border-color;
						}
						.text-muted {
							color: $sidebar-submenu-font-color !important;
						}
						.review-box {
							.testimonial {
								.content {
									border: 1px solid $dark-border-color;
									&:before {
										border-bottom: 10px solid $dark-border-color;
									}
									&:after {
										border-bottom: 9px solid $dark-card-background;
									}
								}
							}
						}
						.calender-widget {
							.cal-date {
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
							}
						}
						.contact-form {
							.theme-form {
								border: 1px solid $dark-border-color;
								.form-icon {
									background-color: $dark-card-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
						}
						.btn-outline-light, .btn-outline-dark, .btn-outline-light-2x {
							color: $white !important;
						}
						.btn-outline-light {
							&:hover {
								color: $black !important;
							}
						}
						.btn-light {
							color: $dark-body-background !important;
							&:hover {
								color: $black !important;
							}
						}
						.border-right {
							border-right: 1px solid $dark-border-color !important;
						}
						.flot-chart-placeholder {
							.flot-text {
								color: $dark-all-font-color !important;
							}
							svg {
								text {
									fill: $dark-all-font-color;
								}
							}
						}
						.chart-overflow {
							&#line-chart,&#column-chart1,&#column-chart2{
								rect {
									fill: $dark-card-background;
								}
							}
							svg {
								> rect {
									fill: $dark-card-background;
								}
								> g {
									>g{
										>g{
											text{
												fill: rgb(177, 177, 178);
											}
										}
									}
									> text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.radial-bar {
							&:after {
								background-color: $dark-card-background;
								color: $dark-all-font-color;
							}
						}
						.bar-chart-widget {
							.bottom-content {
								.num {
									color: $dark-all-font-color;
									.color-bottom {
										color: $dark-all-font-color;
									}
								}
							}
						}
						.b-r-light {
							border-right: 1px solid $dark-border-color !important;
						}
						.chart-container {
							.live-products, .turnover, .monthly, .uses {
								.ct-labels {
									.ct-label {
										color: $white;
									}
								}
								.ct-grid {
									stroke: $white;
								}
							}
							#browser-uses-chart, #website-visiter-chart {
								svg {
									text {
										fill: $dark-all-font-color;
									}
								}
							}
						}
						.status-details {
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.clipboaard-container {
							.form-control {
								background-color: $dark-body-background;
								color: $dark-small-font-color;
								border: 1px solid $dark-border-color;
							}
						}
						.img-thumbnail {
							background-color: $dark-body-background;
							border: 1px solid $dark-border-color;
						}
						.crm-top-widget {
							.media {
								.media-body {
									h4 {
										color: $dark-all-font-color;
									}
									span {
										color: $dark-small-font-color;
									}
								}
							}
						}
						.stat-widget-dashboard {
							.media {
								img {
									filter: brightness(0) invert(75%);
								}
							}
							span {
								color: $dark-all-font-color;
							}
						}
						.support-ticket {
							table {
								tr {
									& + tr {
										border-top: 1px solid $dark-border-color;
									}
									td {
										p, h6 {
											color: $dark-small-font-color;
										}
										span {
											color: $dark-all-font-color;
										}
									}
								}
							}
						}
						.dataTables_wrapper {
							.dataTables_length {
								select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
							}
							.dataTables_length,
							.dataTables_filter,
							.dataTables_info,
							.dataTables_processing,
							.dataTables_paginate {
								color: $dark-all-font-color;
							}
							.dataTables_paginate{
								border: 1px solid $dark-border-color;
							}
							.dataTables_filter {
								input[type="search"] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							table.dataTable.display, table.dataTable.order-column.stripe {
								tbody {
									tr {
										background-color: $dark-card-background;
										&:hover {
											> .sorting_1 {
												background-color: #2e353d;
											}
										}
									}
									tr.odd {
										background-color: $dark-body-background;
										> .sorting_1 {
											background-color: #2e353d;
										}
									}
									tr.even {
										> .sorting_1 {
											background-color: rgba(53, 59, 74, 0.30);
										}
									}
								}
							}
							table.dataTable {
								border: 1px solid $dark-border-color;
								thead {
									th, td {
										border-bottom: 2px solid $dark-border-color;
									}
								}
								input, select {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border: 1px solid $dark-border-color;
								}
								tbody {
									tr {
										background-color: $dark-card-background;
									}
									td.select-checkbox, th.select-checkbox {
										&:before {
											border: 1px solid $dark-small-font-color;
										}
									}
								}
							}
							.table-striped {
								tbody {
									tr {
										&:nth-of-type(odd) {
											background-color: $dark-body-background;
										}
									}
								}
							}
							.dataTables_paginate {
								.paginate_button {
									color: $dark-all-font-color !important;
								}
								.paginate_button.disabled {
									color: $dark-small-font-color !important;
									&:hover, :active {
										color: $dark-small-font-color !important;
									}
								}
							}
							table.dataTable.row-border, table.dataTable.display {
								tbody {
									th, td {
										border-top: 1px solid $dark-border-color;
									}
								}

							}
							table.dataTable.display, table.dataTable.order-column.hover {
								tbody {
									tr.even {
										&:hover {
											> .sorting_1 {
												background-color: #2c3239;
											}
										}
									}
								}
							}
							table.dataTable.hover, table.dataTable.display {
								tbody {
									tr {
										&:hover {
											background-color: #2e353d;
										}
									}
								}
							}
							table.dataTable.cell-border {
								th, td {
									border-top: 1px solid $dark-border-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-left: 1px solid $dark-border-color;
									}
								}
							}
							table.dataTable.order-column, table.dataTable.display {
								tbody {
									tr {
										> .sorting_1,
										> .sorting_2,
										> .sorting_3 {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						#example-style-3_wrapper {
							#example-style-3 {
								tfoot {
									border-top: 2px solid $dark-border-color;
								}
							}
						}
						.page-item.disabled {
							.page-link {
								background-color: $dark-card-background;
								border-color: $dark-border-color;
							}
						}
						.page-link {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							&:hover{
								background-color: $dark-border-color;
							}
						}
						.page-item.active {
							.page-link {
								background-color: $primary-color;
							}
						}
						.ecommerce-widget {
							.icon {
								color: $dark-body-background;
							}
							.total-num {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
						.crm-numbers {
							.col {
								+ .col {
									border-left: 1px solid $dark-border-color;
								}
								> span {
									color: $dark-small-font-color;
								}
							}
						}
						.flot-chart-container-small {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
						}
						.product-box {
							.product-details {
								h6 {
									color: $dark-all-font-color;
								}
								span {
									color: $dark-small-font-color;
								}
							}
							.product-img {
								.product-hover {
									ul {
										li{
											background-color: $dark-card-background;
											&:hover{
												background-color: $black;
												color: $white;
											}
										}
									}
								}
							}
						}
						.product-table{
							#API-2_wrapper{
								#API-2{
									tbody{
										td{
											span, p{
												color: $dark-small-font-color;
											}
										}
									}
								}
							}
							h6{
								color: $dark-small-font-color;
							}
						}
						.nav-material.nav-tabs {
							border-bottom: 1px solid $dark-border-color;
							.nav-item {
								&.show{

								}
								.nav-link{
									color: $dark-all-font-color;
								}
							}
							.nav-link{
								&.active,
								&:focus,
								&:hover{
									color: $primary-color;
								}
							}
						}
						.br-theme-bars-1to10, .br-theme-bars-movie, .br-theme-bars-pill, .br-theme-bars-reversed, .br-theme-bars-horizontal{
							.br-widget{
								a{
									background-color: $dark-border-color;
									&.br-active,
									&.br-selected{
										background-color: $primary-color;
									}
								}
							}
						}
						.br-theme-bars-square {
							.br-widget{
								a{
									border: 2px solid $dark-border-color;
									background-color: $dark-card-background ;
									color: $dark-all-font-color;
									&.br-active,
									&.br-selected{
										border: 2px solid $primary-color;
										color: $primary-color;
									}
								}
							}
						}
						.br-theme-fontawesome-stars, .br-theme-fontawesome-stars-o {
							.br-widget{
								a{
									&:after{
										color: $dark-border-color;
									}
									&.br-selected,
									&.br-active{
										&:after{
											color: $primary-color;
										}
									}
								}
							}
						}
						.scroll-demo{
							border: 1px solid $dark-border-color;
						}
						.search-form{
							input{
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
								filter: brightness(0) invert(100%);
							}
						}
						.cd-timeline-content{
							background-color: $dark-border-color;
							&::before{
								border-left: 7px solid $dark-border-color;
							}
						}
						.cd-timeline-block{
							&:nth-child(even) {
								.cd-timeline-content{
									&::before {
										border-right-color: $dark-border-color;
										border-left: transparent;
									}
								}
							}
						}
						.breadcrumb{
							background-color: $dark-body-background;
						}
						.user-status{
							table{
								td, th{
									border-top: none !important;
								}
							}
						}
						#donut-color-chart-morris-daily, #donut-color-chart-morris, #browser-uses-chart, #website-visiter-chart{
							svg{
								opacity: 0.5;
							}
						}
					}
					.customer-review {
						.media-body {
							.cust-name {
								color: $dark-all-font-color;
							}
							.cust-des {
								color: $dark-small-font-color;
							}
						}
					}
					.form-builder-header-1, .form-builder-2-header {
						background-color: $dark-body-background;
					}
					.form-builder {
						.nav-primary {
							.show {
								> .nav-link {
									color: $white;
								}
							}
							.nav-link {
								color: $dark-all-font-color;
								&.active {
									color: $white;
								}
							}
							.nav-pills.nav-primary {
								.show {
									> .nav-link {
										color: $white;
									}
								}
								.nav-link {
									color: $dark-all-font-color;
									&.active {
										color: $white;
									}
								}
							}

						}
						.drag-box {
							fieldset {
								border: 1px solid $dark-border-color;
							}
						}
						.help-block {
							color: $dark-small-font-color;
						}
					}
					#viewhtml {
						.render {
							background-color: $dark-card-background;
							color: $dark-all-font-color;
							border-color: $dark-border-color;
						}
					}
					.form-builder-column {
						.drag-bx {
							border: 1px dotted $dark-border-color;
						}
					}
					.wizard-4 {
						ul.anchor {
							li {
								a.disabled {
									color: $dark-body-background;
								}
							}
						}
					}
					.gallery-with-description {
						h4 {
							color: $dark-all-font-color;
						}
						a {
							> div {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
					}
					.jsgrid-grid-header {
						background-color: $dark-card-background;
						border: 1px solid $dark-border-color;
					}
					.jsgrid-header-row, .jsgrid-filter-row {
						> .jsgrid-header-cell,
						> .jsgrid-cell {
							background: $dark-card-background;
							border: 1px solid $dark-border-color;
							input {
								background-color: $dark-body-background;
								border-color: $dark-border-color;
								color: $dark-all-font-color;
							}
						}
						select {
							background-color: $dark-body-background;
							border-color: $dark-border-color;
							color: $dark-all-font-color;
						}
					}
					.jsgrid-row {
						> .jsgrid-cell {
							background-color: $dark-card-background;
						}
					}
					.jsgrid-alt-row {
						> .jsgrid-cell {
							background-color: $dark-body-background;
						}
					}
					.jsgrid-cell, .jsgrid-grid-body {
						border: 1px solid $dark-border-color;
					}
					.jsgrid-selected-row {
						> .jsgrid-cell {
							background-color: #2e353d;
						}
					}
					.jsgrid {
						.jsgrid-pager {
							[class*="jsgrid-pager"] {
								border: 1px solid $dark-border-color;
							}
							.jsgrid-pager-page {
								a {
									color: $dark-all-font-color;
								}
							}
						}

					}
					.jsgrid-pager-current-page {
						color: $dark-all-font-color;
						font-weight: 700;
					}
					.sub-title {
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
					}
					.navs-icon {
						li {
							a {
								color: $dark-all-font-color;
								&:hover {
									color: $primary-color;
								}
							}
						}
						.separator{
							border: 1px solid $dark-border-color;
						}
					}
					.default-according{
						li{
							.text-muted{
								color: $dark-all-font-color!important;
							}
						}
					}
					.navs-icon.default-according.style-1 {
						li{
							button{
								&:hover
								{
									color: $primary-color!important;
								}
							}
						}
					}
					.nav-list{
						.nav-list-disc{
							li{
								a{
									color: $dark-all-font-color;
								}
								&:hover{
									color: $primary-color;
									a{
										color: $primary-color;
									}
								}
							}

						}
					}
					.navs-dropdown{
						.onhover-show-div{
							background-color: $dark-card-background;
							box-shadow: 0 0 2px 2px $dark-border-color;
						}
					}
					.onhover-dropdown{
						&:hover {
							.onhover-show-div{
								&:before{
									border-bottom: 7px solid $dark-card-background;
								}
							}
						}
					}
					.default-according {
						.card {
							.btn-link {
								color: $dark-all-font-color;
							}
							.card-body {
								color: $dark-small-font-color;
							}
						}
					}
					.border {
						border: 1px solid $dark-border-color !important;
					}
					.blog-box {
						.blog-date {
							color: $dark-all-font-color;
						}
						.blog-details, .blog-details-main {
							.blog-social {
								li {
									color: $dark-small-font-color;
									border-right: 1px solid $dark-border-color;
									&:first-child {
										border-right: 1px solid $dark-border-color;
									}
									&:last-child {
										border-right: none;
									}
								}
							}
							p {
								color: $dark-all-font-color;
							}
							.single-blog-content-top {
								border-top: 1px solid $dark-border-color;
								p {
									color: $dark-small-font-color;
								}
							}
						}
					}
					.comment-box {
						.media {
							h6 {
								span {
									color: $dark-small-font-color;
								}
							}
							img {
								border: 1px solid $dark-border-color;
							}
						}
						p {
							color: $dark-small-font-color;
						}
						.comment-social {
							li {
								color: $dark-small-font-color;
								&:first-child {
									border-right: 1px solid $dark-border-color;
								}
							}
						}
						hr{
							border-top: 1px solid $dark-border-color;
						}
					}
					.table-hover {
						tbody {
							tr {
								&:hover {
									background-color: $dark-body-background;
									td, th {
										color: $white;
									}
								}
							}
						}
					}
					ul.the-icons {
						li {
							border: 1px dotted $dark-border-color;
							color: $sidebar-submenu-font-color;
						}
					}
					.button-builder-wrap {
						.box {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
						.button-preview {
							h2 {
								color: $dark-all-font-color;
							}
						}
						pre.well {
							background-color: $dark-card-background !important;
						}
					}
					#cal-basic {
						.fc-toolbar {
							.fc-left, .fc-right {
								.fc-next-button {
									.fc-icon-right-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
								.fc-prev-button {
									.fc-icon-left-single-arrow {
										&:after {
											color: $dark-small-font-color;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
								}
								.fc-button-group{
									.fc-month-button{
										color: $white !important;
									}
								}
							}
							.fc-right{
								.btn-light{
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					#cal-basic-view{
						.fc-toolbar {
							.fc-right {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-left {
								.fc-button-group {
									.fc-agendaWeek-button, .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.fc-button-group {
						.fc-basicWeek-button, .fc-basicDay-button, .fc-agendaWeek-button, .fc-agendaDay-button {
							color: $black !important;
						}
						.fc-month-button {
							color: $white !important;
						}
					}
					#cal-agenda-view {
						.fc-toolbar {
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
							.fc-right {
								.fc-button-group {
									.fc-month-button, .btn-light {
										color: $black !important;
									}
									.fc-agendaWeek-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
						}
					}
					.basic-calendar, #cal-bg-events, #cal-event-colors {
						.fc-toolbar {
							.fc-right {
								.fc-button-group {
									.fc-basicWeek-button , .btn-light {
										color: $black !important;
									}
									.fc-month-button {
										color: $white !important;
										&:hover {
											color: $black !important;
										}
									}
								}
							}
							.fc-left {
								.btn-light {
									color: $dark-all-font-color !important;
									&:hover{
										background-color: $dark-body-background !important;
										border: 1px solid $dark-body-background !important;
									}
								}
							}
						}
					}
					.border-widgets {
						> .row {
							> div {
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}
						}
					}
					.whether-widget {
						.whether-widget-top {
							background-color: $primary-color;
							.details {
								h4 {
									color: $white;
								}
							}
						}
						.whether-widget-bottom {
							h5 {
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.crm-activity {
						> li {
							+ li {
								border-top: 1px solid $dark-border-color;
							}
							h6 {
								color: $dark-all-font-color;
							}
						}
						ul.dates {
							li {
								color: $dark-small-font-color;
							}
						}
					}
					.default-widget-count {
						h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color
						}
						.icon-bg {
							color: #2e3945;
						}
						.left {
							i {
								color: $dark-body-background;
								text-shadow: 1px 1px 1px $dark-card-background;
							}
						}
					}
					#donut-color-chart-morris, #donut-color-chart-morris-daily {
						svg {
							text {
								fill: $dark-all-font-color;
							}
						}
					}
					.testimonial-default {
						.slide--item {
							p {
								color: $dark-all-font-color !important;
							}
						}
					}
					.custom-card {
						.card-profile {
							img {
								background-color: $dark-card-background;
							}
						}
						.profile-details {
							h6 {
								color: $dark-small-font-color;
							}
						}
						.card-social {
							li {
								a {
									color: $dark-small-font-color;
								}
							}
						}
						.card-footer {
							> div {
								h6 {
									color: $dark-small-font-color;
								}
								+ div {
									border-left: 1px solid $dark-border-color;
								}
							}

						}
					}
					.form-control {
						background-color: $dark-body-background;
						color: $dark-all-font-color;
						border: 1px solid $dark-border-color;
					}
					.checkbox, .radio {
						label {
							&::before {
								background-color: $dark-body-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
					.dropdown-basic {
						.dropdown {
							.dropdown-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover {
										background-color: $dark-card-background;
									}
								}
								.dropdown-divider {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropup-basic {
						.dropup {
							.dropup-content {
								background-color: $dark-body-background;
								a {
									color: $dark-all-font-color;
									&:hover,
									&.active {
										background-color: $dark-card-background;
									}
								}
							}
						}
					}
					.dropzone.dz-clickable {
						.dz-message {
							h6 {
								color: $dark-all-font-color;
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.dropzone {
						.dz-preview {
							background-color: $dark-body-background;
							.dz-details {
								background-color: $dark-card-background;
							}
						}
					}
					.browser-widget {
						.media-body {
							column-rule: 1px solid $dark-border-color;
							h4 {
								color: $dark-all-font-color;
								span {
									color: $dark-all-font-color;
								}
							}
							span {
								color: $dark-small-font-color;
							}
						}
					}
					.email-wrap {
						.email-app-sidebar {
							.media {
								img {
									border: 2px solid $dark-body-background;
								}
							}
							.main-menu {
								> li {
									a {
										color: $dark-all-font-color;
										&:hover {
											background-color: $dark-body-background;
										}
									}
								}
							}
						}
						.email-right-aside {
							.email-body {
								.inbox {
									.media.active {
										background-color: $dark-body-background;
									}
								}
							}
						}
						.media-body {
							h6 {
								color: $dark-all-font-color;
								small {
									color: $dark-small-font-color;
									span {
										color: $dark-small-font-color;
									}
								}
							}
							p {
								color: $dark-small-font-color;
							}
						}
						.email-top {
							border-bottom: 1px solid $dark-border-color;
						}
						p {
							color: $dark-small-font-color;
						}
						.email-content {
							.email-top {
								.user-emailid:after {
									border: 1px solid $dark-border-color;
								}
							}
						}
					}
					.dropdown-menu {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
						a {
							color: $dark-all-font-color;
							&:hover {
								background-color: $dark-card-background;
							}
						}
					}
					.icon-lists {
						div {
							i {
								color: $dark-small-font-color;
							}
							&:hover {
								background-color: $dark-body-background;
							}
						}
					}
					.widget-joins {
						.media {
							.media-body {
								> span {
									color: $dark-small-font-color;
								}
							}
							.details {
								border-left: 1px solid $dark-border-color;
							}
						}
						&:before,
						&:after {
							background-color: $dark-border-color;
						}
					}
					.redial-social-widget {
						i {
							background-color: $dark-card-background;
						}
					}
					.social-widget-card {
						h5, h4 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.b-b-light {
						border-bottom: 1px solid $dark-border-color !important;
					}
					.b-r-dark {
						border-right: 1px solid $white !important;
					}
					.testimonial {
						i {
							color: $dark-border-color;
						}
						p {
							color: $dark-all-font-color;
						}
						h5 {
							color: $dark-all-font-color;
						}
						span {
							color: $dark-small-font-color;
						}
					}
					.grid-showcase {
						span {
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
						}
					}
					.grid-align {
						.row {
							background-color: $dark-border-color;
							border: 1px solid $dark-border-color;
						}
					}
					.border-style {
						.card {
							border: 1px solid $dark-border-color;
						}
					}
					.offer-style {
						.card {
							border: 1px dashed $dark-border-color;
						}
					}
					.page-builder {
						.btn-grid{
							background-color: $dark-card-background;
							color: $dark-small-font-color !important;
							border-right: 1px solid $dark-border-color;
						}
						.ge-mainControls {
							.ge-addRowGroup {
								.ge-row-icon {
									.column{
										border-left: 3px solid $dark-card-background;
									}
								}
							}
						}
						.btn-code, .btn-screen{
							color: $dark-small-font-color;
							border-right: 1px solid $dark-border-color;
						}
						.ge-canvas.ge-editing {
							.row{
								background-color: $dark-body-background;
							}
							.column{
								border: 1px solid $dark-border-color;
								background-color: $dark-card-background;
							}
							.ge-tools-drawer{
								.ge-details{
									input{
										background-color: $dark-body-background;
										border-color: $dark-border-color;
										color: $dark-small-font-color;
									}
									.btn-group{
										a{
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.ge-content-type-ckeditor{
							color: $dark-all-font-color;
						}
					}
					.pricing-wrapper-card{
						.pricing-card{
							background-color: $dark-card-background;
						}
						.card-title{
							h3{
								color: rgba(206, 212, 218, 0.3);
							}
							h5{
								color: $dark-all-font-color;
							}
						}
						.card-price {
							h1{
								small{
									color: $dark-small-font-color;
								}
							}
						}
						.card-description{
							li{
								color: $dark-all-font-color;
								&::before {
									color: rgba(255,255,255,0.15);
								}
							}
						}
					}
					.search-page {
						.info-block{
							+ .info-block{
								border-top: 1px solid $dark-border-color;
							}
						}
					}
					.card-absolute{
						.bg-primary, .bg-secondary{
							h5{
								color: $white;
							}
						}
					}
					/* body end*/
				}
				footer {
					border-top: 1px solid $dark-body-background;
					background-color: $dark-card-background;
				}
				.custom-select, .custom-file-label {
					background: $dark-body-background;
					color: $dark-all-font-color;
				}
				.footer-fix{
					background-color: $dark-card-background;
					border-top: 1px solid $dark-border-color;
				}
			}
			.dt-button-info {
				background-color: $dark-card-background;
				border: 1px solid $dark-small-font-color;
				h2 {
					background-color: $dark-card-background;
				}
			}
			.chat-box {
				.about {
					.name {
						color: $dark-all-font-color;
					}
				}
				.chat-menu {
					border-left: 1px solid $dark-border-color;
					.nav-tabs {
						border-bottom: 1px solid $dark-border-color;
						.nav-item {
							.nav-link {
								&.active {
									color: $dark-all-font-color !important;
								}
							}
						}
					}

					.user-profile {
						.image {
							.icon-wrapper {
								background-color: $dark-card-background;
								box-shadow: 1px 1px 3px 1px $dark-body-background;
							}
							.avatar{
								img{
									border: 5px solid $dark-border-color;
								}
							}
						}
						.social-media {
							a {
								color: $dark-small-font-color;
							}
						}
						.follow {
							span {
								color: $dark-small-font-color;
							}
							.follow-num {
								color: $dark-all-font-color;
							}
						}
					}
				}
				.status {
					color: $dark-small-font-color;
					p {
						color: $dark-all-font-color !important;
					}
				}
				.chat-right-aside {
					.chat {
						.chat-header {
							border-bottom: 1px solid $dark-border-color;
							.chat-menu-icons {
								li {
									a {
										i {
											color: $dark-small-font-color;
										}
									}
								}
							}
						}
						.chat-msg-box {
							.my-message {
								border: 1px solid $dark-border-color
							}
							.message {
								color: $dark-all-font-color;
							}
							.other-message {
								background-color: $dark-body-background;
							}
						}
						.chat-message {
							background-color: $dark-card-background;
							border-top: 1px solid $dark-border-color;
							.input-group {
								.form-control {
									background-color: $dark-card-background;
								}
							}
						}
					}
				}
				.chat-history {
					.call-icons {
						ul {
							li {
								border: 1px solid $dark-border-color;
								a {
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
			}
			pre {
				background-color: $dark-border-color;
				color: $dark-all-font-color;
			}
			.scorlled {
				background-color: $dark-body-background;
			}
			.input-group-air {
				box-shadow: 0 3px 20px 0 $dark-border-color;
			}
			.input-group-solid {
				.input-group-text, .form-control {
					background: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
			.semilight-bg-color, .header-bg-color {
				background-color: $white;
			}
			.list-group-item {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.3);
				color: $dark-all-font-color;
				&.active {
					background-color: $primary-color;
					border-color: $primary-color;
					color: $white;
				}
				&:hover,
				&:focus {
					z-index: 1;
					text-decoration: none;
				}
			}
			.list-group-item-action {
				&:hover:not(.active),
				&:focus {
					background-color: $dark-body-background;
				}
			}
			.list-group-flush {
				.list-group-item {
					border-left: 0;
					border-right: 0;
					border-radius: 0;
				}
			}
			.list-group-item-primary {
				color: #004085;
				background-color: #b8daff;
			}
			.list-group-item-secondary {
				color: #383d41;
				background-color: #d6d8db;
			}
			.list-group-item-success {
				color: #155724;
				background-color: #c3e6cb;
			}
			.list-group-item-danger {
				color: #721c24;
				background-color: #f5c6cb;
			}
			.list-group-item-warning {
				color: #856404;
				background-color: #ffeeba;
			}
			.list-group-item-info {
				color: #0c5460;
				background-color: #bee5eb;
			}
			.list-group-item-light {
				color: #818182;
				background-color: #fdfdfe;
			}
			.list-group-item-dark {
				color: #1b1e21;
				background-color: #c6c8ca;
			}
			.auth-bg {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input {
									color: $dark-all-font-color;
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
								}
								label {
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									color: $dark-all-font-color;
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
					h3, h4, h6{
						color: $white;
					}
				}
			}
			.auth-bg-video {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.authentication-box {
					h4, h3 {
						color: $white;
					}
					h6 {
						color: $dark-small-font-color;
					}
					.card {
						background-color: $dark-card-background;
						.theme-form {
							.form-group {
								input[type=text],
								input[type=password] {
									background-color: $dark-body-background;
									border: 1px solid $dark-border-color;
									color: $dark-all-font-color;
								}
							}
							.checkbox {
								label {
									&::before {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
					}
				}
			}
			.error-wrapper {
				.maintenance-icons {
					li {
						i {
							color: $dark-border-color;
						}
						&:nth-child(3) {
							i {
								color: $dark-border-color;
							}
						}
					}
				}
			}
			.modal-content {
				background-color: $dark-card-background;
				border: 1px solid rgba(255, 255, 255, 0.2);
				.modal-header {
					border-bottom: 1px solid $dark-border-color;
					.close {
						color: $dark-small-font-color;
					}
				}
				.modal-footer {
					border-top: 1px solid $dark-border-color;
				}
			}
			.comingsoon {
				background-blend-mode: overlay;
				background-color: rgba(54, 64, 74, 0.7);
				.comingsoon-inner {
					h5 {
						color: $dark-all-font-color;
					}
					.countdown {
						border-top: 1px solid $dark-border-color;
						border-bottom: 1px solid $dark-border-color;
						.title {
							color: $dark-all-font-color;
						}
					}
					.coming-soon-bottom-link {
						a {
							color: $dark-all-font-color;
						}
					}
					img{
						filter: brightness(1) invert(100%);
					}
				}
			}
			.authentication-main {
				.auth-innerleft {
					background: url(/assets/images/auth-layer.png),  rgba(54, 64, 74, 0.9);
					background-blend-mode: overlay;
					.text-center {
						img {
							filter: brightness(1) invert(100%);
						}
					}
					.social-media{
						li{
							color: $dark-body-background;
						}
					}
				}
				.auth-innerright {
					background-color: $dark-border-color;
					.reset-password-box {
						.card {
							background-color: $dark-card-background;
						}
						.reset-password-link {
							color: $dark-small-font-color;
						}
						.theme-form {
							.form-group {
								label {
									color: $dark-small-font-color;
								}
								.form-control {
									background-color: $dark-body-background;
									color: $dark-all-font-color;
									border-color: $dark-border-color;
								}
							}
							.opt-box {
								background-color: $dark-border-color;
							}
						}

					}
					.authentication-box {
						h4 {
							color: $dark-all-font-color;
						}
						h6 {
							color: $dark-small-font-color;
						}
						h3{
							color: $dark-all-font-color;
						}
						.card {
							background-color: $dark-card-background;
							.theme-form {
								.form-group {
									input[type=text],
									input[type=password] {
										background-color: $dark-body-background;
										border: 1px solid $dark-border-color;
										color: $dark-all-font-color;
									}
								}
								.checkbox {
									label {
										&::before {
											background-color: $dark-body-background;
											border: 1px solid $dark-border-color;
										}
									}
								}
							}
						}
					}
				}
			}
			.vertical-menu-main{
				background-color: $dark-card-background;
			}
			.pixelstrap {
				a{
					color: $dark-all-font-color;
					&:focus,
					&:active,
					&:hover{
						color: $dark-all-font-color;
					}
				}
				> {
					li {
						> a.highlighted{
							&:before
							{
								background-color: $dark-card-background;
							}
							&:after
							{
								background-color: $dark-border-color;
							}
						}
					}
				}
			}
			@media (min-width: 1200px){
				.pixelstrap{
					ul {
						background: $dark-body-background;
						border: 1px solid $dark-border-color;
						a{
							&:hover,
							&:focus,
							&:active,
							&.highlighted{
								color: $primary-color;
							}
							&.current{
								background-color: $dark-body-background;
								color: $primary-color;
							}
						}
					}
				}
			}
			.mega-menu {
				.title{
					color: $dark-all-font-color;
					border-bottom: 1px solid $dark-border-color;
				}
				.galleria-list{
					.galleria{
						> div {
							.username{
								color: $dark-all-font-color;
								small{
									color: $dark-small-font-color;
								}
							}
						}
					}
				}
				.list-unstyled{
					div{
						a{
							&:hover{
								color: $primary-color;
							}
						}
					}
				}
			}
			.default-according {
				.card {
					background-color: $dark-card-background;
					.btn-link{
						background-color: $dark-card-background;
						border: 1px solid $dark-card-background;
						color: $white;
					}
					.text-muted{
						color: $dark-small-font-color !important;
					}
				}
				.bg-primary{
					.btn-link{
						background-color: $primary-color;
						border: 1px solid $primary-color;
					}
				}
				.bg-secondary{
					.btn-link{
						background-color: $secondary-color;
						border: 1px solid $secondary-color;
					}
				}
			}
			.collapse{
				.card-body{
					background-color: $dark-card-background;
				}
			}
			@media screen and (max-width: 1660px){
				.chat-box{
					.chat-history{
						.call-icons{
							ul{
								li{
									border: 1px solid $dark-border-color;
								}
							}
						}
						.total-time{
							h2{
								color: $dark-all-font-color;
							}
						}
					}
				}
				.caller-img{
					img{
						opacity: 0.7;
					}
				}
				.chat-box {
					.chat-history {
						.call-content {
							>div{
								background-color: rgba(0, 0, 0, 0.75);
								background-blend-mode: overlay;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1366px) {
				//crm dashboard
				.crm-numbers {
					.col {
						border-bottom: 1px solid $dark-border-color;
						+.col{
							border-left: 1px solid $dark-border-color;
							& + .col{
								border-left: 1px solid $dark-border-color;
								& + .col{
									border-left:1px solid $dark-border-color;
								}
							}
						}
					}
				}
			}
			@media only screen and (max-width: 1199px){
				.sm{
					background-color: $dark-card-background;
					border: 1px solid $dark-border-color;
				}
				.pixelstrap{
					.mobile-back{
						border-bottom: 1px solid $dark-border-color;
					}
					ul{
						li{
							border-top: 1px solid $dark-border-color;
						}
						a{
							background: $dark-card-background;
							&:hover,
							&:focus,
							&:active{
								background: $dark-card-background;
							}
						}
					}
				}
				.chat-menu {
					border-top: 1px solid $dark-border-color;
					background-color: $dark-card-background;
				}
				.error-wrapper{
					.maintenance-icons{
						li{
							i{
								color: $dark-color;
							}
						}
					}
				}
				.border-widgets{
					> .row{
						>div:nth-child(1) {
							border-bottom: 1px solid $dark-border-color;
						}
						>div:nth-child(2) {
							border-bottom: 1px solid $dark-border-color;
						}
					}
				}
				.crm-numbers {
					.col {
						+.col{
							border-left: 1px solid $dark-border-color;
							& + .col{
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
			}
			.pixelstrap{
				ul{
					background: $dark-card-background;
				}
			}
			@media only screen and (max-width: 991px){
				.crm-numbers {
					.col {
						+.col{
							border-left:1px solid $dark-border-color;
						}
					}
				}
			}
			@media only screen and (max-width: 767px){
				//crm dashboard
				.border-widgets{
					> .row{
						> div{
							+div{
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
			}
			@media only screen and (max-width: 575px){
				.user-profile {
					.hovercard {
						.info {
							.user-designation {
								border-top: 1px solid $dark-border-color;
								border-bottom: 1px solid $dark-border-color;
							}
						}
					}
				}
				.border-widgets {
					> .row {
						> div {
							+ div {
								border-left: 1px solid $dark-border-color;
							}
						}
					}
				}
				.widget-joins {
					.border-after-xs {
						&:after {

							background-color: $dark-border-color;
						}
					}
				}
			}
			@media only screen and (max-width: 480px){
				.business-card {
					.b-l-light{
						border-left: 1px solid $dark-border-color!important;
					}
				}
			}
			@media only screen and (max-width: 360px){
				.border-widgets{
					> .row{
						> div{
							& + div{
								& + div{
									border-bottom: 1px solid $dark-border-color;
								}
							}
						}
					}
				}
			}
		}
		.lg-backdrop {
			background-color: $dark-body-background;
		}
		.lg-outer {
			.lg-thumb-outer {
				background-color: $dark-card-background;
			}
		}
		.drag {
			background-color: $dark-card-background;
			form {
				background: $dark-card-background;
				box-shadow: 0 0 30px $dark-border-color;
				border: 1px dotted $dark-border-color;
				input {
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
				.help-block {
					text-transform: capitalize;
					color: $dark-small-font-color;
				}
			}
		}
		.draggable {
			input {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			p {
				color: $dark-small-font-color;
			}
			select {
				background-color: $dark-body-background;
				color: $dark-all-font-color;
				border: 1px solid $dark-border-color;
			}
			.radio, .checkbox {
				label {
					&::before {
						background-color: $dark-body-background;
						border: 1px solid $dark-border-color;
					}
				}
			}
		}
		.select2-container--default{
			.select2-selection--multiple, .select2-selection--single{
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color !important;
			}
			.select2-search--inline{
				.select2-search__field{
					color: $dark-all-font-color;
				}
			}
			.select2-selection--single{
				.select2-selection__rendered{
					color: $dark-all-font-color;
				}
			}
			.select2-search--dropdown {
				.select2-search__field{
					background-color: $dark-body-background;
					border: 1px solid $dark-border-color;
					color: $dark-all-font-color;
				}
			}
		}
		.select2-dropdown{
			background-color: $dark-body-background;
			border: 1px solid $dark-border-color;
		}
		.select2-drpdwn {
			.form-control-primary {
				border-color: $primary-color !important;
				color: $primary-color !important;
			}
			.form-control-secondary {
				border-color: $secondary-color !important;
				color: $secondary-color !important;
			}
			.form-control-success {
				border-color: $success-color !important;
				color: $success-color !important;
			}
			.form-control-info {
				border-color: $info-color !important;
				color: $info-color !important;
			}
			.form-control-warning {
				border-color: $warning-color !important;
				color: $warning-color !important;
			}
			.form-control-danger {
				border-color: $danger-color !important;
				color: $danger-color !important;
			}
			.form-control-inverse {
				border-color: $white !important;
				color: $white !important;
			}
			.form-control-primary-fill{
				background-color: $primary-color !important;
				color: $white !important;
			}
			.form-control-secondary-fill{
				background-color: $secondary-color !important;
				color: $white !important;
			}
			.form-control-success-fill{
				background-color: $success-color !important;
				color: $white !important;
			}
			.form-control-info-fill{
				background-color: $info-color !important;
				color: $white !important;
			}
			.form-control-warning-fill{
				background-color: $warning-color !important;
				color: $white !important;
			}
			.form-control-danger-fill{
				background-color: $danger-color !important;
				color: $white !important;
			}
			.form-control-inverse-fill{
				background-color: $white !important;
				color: $black !important;
			}
		}
		.select2-container--default.select2-container--disabled{
			.select2-selection--single{
				background-color: $dark-border-color;
			}
		}
		.theme-form {
			.form-group {
				select.form-control:not([size]):not([multiple]) {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color;
				}
				input[type=text], input[type=email], input[type=search],
				input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local], input[type=time], input[type=datetime-local], input[type=month], input[type=week],
				input[type=url], input[type=file], select {
					border-color: $dark-border-color;
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					&::-webkit-input-placeholder {
						color: $dark-small-font-color;
					}
				}
				.form-control {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
				}
				textarea {
					border-color: $dark-border-color;
				}
			}
			.form-divider{
				border-top: 1px solid $dark-border-color;
				&::before{
					background: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.CodeMirror{
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
			color: $dark-all-font-color;
			.CodeMirror-code{
				pre{
					background: $dark-card-background;
				}
			}
		}
		.editor-toolbar{
			border-top: 1px solid $dark-small-font-color;
			border-left: 1px solid $dark-small-font-color;
			border-right: 1px solid $dark-small-font-color;
			a{
				color: $dark-all-font-color !important;
				&:hover,
				&.active{
					background: $dark-border-color;
				}
			}
			i.separator{
				border-left: 1px solid $dark-small-font-color;
				border-right: 1px solid $dark-small-font-color;
			}
		}
		.editor-toolbar.disabled-for-preview {
			a{
				&:not(.no-disable){
					background: $dark-border-color;
				}
			}
		}
		.editor-preview {
			background-color: $dark-card-background;
		}
		.editor-toolbar.fullscreen, .editor-preview-side{
			background: $dark-card-background;
			border: 1px solid $dark-small-font-color;
		}
		.u-step{
			background: $dark-border-color;
			&.active,
			&.current{
				background: $primary-color;
				color: $white;
			}
		}
		.u-step-title, .u-pearl-title{
			color: $dark-all-font-color;
		}
		.u-step-number{
			background-color: $dark-card-background;
		}
		.u-pearl{
			&:before{
				background-color: $dark-border-color;
			}
		}
		.u-pearl-number, .u-pearl-icon{
			background: $dark-body-background;
			border: 2px solid $dark-body-background;
		}
		.u-pearl.disabled {
			.u-pearl-icon, .u-pearl-number{
				background: $dark-border-color;
				border: 2px solid $dark-border-color;
			}
			&:after {
				background-color: #2e353d;
			}
		}
		.u-pearl.error{
			&:after{
				background-color: $dark-body-background;
			}
		}
		.note-editor.note-frame{
			border-color: $dark-border-color;
			.note-editing-area {
				.note-editable{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
				}
			}
		}
		.swal-modal{
			background-color: $dark-card-background;
			.swal-title{
				color: $dark-all-font-color;
			}
			.swal-text{
				color: $dark-small-font-color;
			}
			.swal-content__input{
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
		}
		.nav-tabs{
			border-bottom: 1px solid $dark-border-color;
			.nav-link{
				&.active{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-border-color $dark-border-color $dark-card-background;
				}
				&:hover,
				&:focus{
					border-color: $dark-border-color $dark-border-color $dark-border-color;
				}
			}
			.nav-item.show{
				.nav-link{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border-color: $dark-small-font-color $dark-border-color $dark-card-background;
				}
			}
		}
		.nav-tabs.nav-bottom {
			.nav-item {
				.nav-link{
					&.active,
					&.focus,
					&.hover{
						border-color: $dark-card-background $dark-border-color $dark-border-color;
					}
				}
			}
		}
		.nav-material.nav-left, .nav-material.nav-right {
			.nav-link{
				color: $dark-all-font-color;
				&.active{
					color: $primary-color;
				}
			}
			.show {
				> .nav-link{
					color: $primary-color;
				}
			}
		}
		.nav-material.nav-left.nav-secondary {
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					border-left-color: $secondary-color;
					color: $secondary-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $secondary-color;
				color: $secondary-color !important;
			}
			.nav-item.show{
				color: $secondary-color !important;
				border-left-color: $secondary-color;
			}
		}
		.nav-material.nav-left.nav-success{
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					color: $success-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $success-color;
				color: $success-color !important;
			}
			.nav-item.show{
				color: $success-color !important;
				border-left-color: $success-color;
			}
		}
		.nav-material.nav-right.nav-info{
			.nav-link {
				&.active,
				&:focus,
				&:hover{
					color: $info-color !important;
				}
			}
			.show > .nav-link {
				border-left-color: $info-color;
				color: $info-color !important;
			}
			.nav-item.show{
				color: $info-color !important;
				border-left-color: $info-color;
			}
		}
		.nav-material.nav-secondary {
			.nav-link{
				&.active,
				&:focus,
				&:hover{
					color: $secondary-color !important;
				}
			}
			.nav-item{
				&.show{
					color: $secondary-color !important;
				}
			}
		}
		.nav-material.nav-success {
			.nav-link{
				&.active,
				&:focus,
				&:hover{
					color: $success-color !important;
				}
			}
			.nav-item{
				&.show{
					color: $success-color !important;
				}
			}
		}
		.dropdown-divider{
			border-top: 1px solid $dark-border-color;
		}
		.icon-hover-bottom {
			background-color: $dark-card-background;
			box-shadow: 0px 0px 1px 1px $dark-border-color;
			.icon-title {
				color: $dark-all-font-color;
			}
			span {
				color: $dark-small-font-color;
			}
			.form-group {
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.icon-first {
				i {
					color: $dark-small-font-color;
				}
			}
		}
		code {
			background-color: #434f5a;
		}
		#cd-timeline{
			&::before {
				background: $dark-border-color;
			}
		}
		.timeliny{
			border-top: 1px solid $dark-border-color;
			border-bottom: 1px solid $dark-border-color;
			&::before{
				background-color: $white;
			}
			.timeliny-dot{
				background-color: $dark-card-background;
				border: 1px solid $white;
				&::before {
					color: $dark-all-font-color;
				}
			}
			.timeliny-timeline{
				.timeliny-timeblock{
					&.active{
						.timeliny-dot{
							&::before{
								color: $dark-all-font-color;
							}
						}
					}
					&:not(.inactive):not(.active){
						.timeliny-dot{
							&:hover {
								background-color: $white;
								&::before{
									color: $dark-all-font-color;
								}
							}
						}
					}
					.timeliny-dot{
						&:hover{
							&::after{
								background-color: $dark-card-background;
								border: 1px solid $dark-border-color;
								color: $dark-all-font-color;
							}
						}
					}
				}
			}
		}
		.todo{
			.todo-list-wrapper{
				#todo-list{
					li {
						.task-container{
							background: $dark-card-background;
							border-bottom: 1px solid $dark-border-color;
							.task-label{
								color: $dark-all-font-color;
							}
							&:hover{
								h4{
									color: $white;
								}
							}
							.task-action-btn{
								.action-box{
									&:hover:not(.active) {
										background: $dark-card-background;
										border: 1px solid $dark-border-color;
									}
								}
							}
						}
						&.completed{
							.task-container{
								.task-label{
									color: $primary-color;
								}
								.complete-btn{
									&:hover{
										border: 1px solid $success-color !important;
									}
								}
							}
						}
					}
				}
				.todo-list-footer {
					.new-task-wrapper{
						textarea{
							background-color: $dark-card-background;
							border: 1px solid $dark-border-color;
							color: $dark-all-font-color;
						}
					}
				}

			}
		}
		.user-profile {
			.ttl-info {
				h6 {
					color: $dark-small-font-color;
				}
				span {
					color: $dark-all-font-color;
				}
			}
			.hovercard {
				.info {
					.title {
						a{
							color: $dark-all-font-color;
						}
					}
				}
				.user-image{
					.avatar{
						img{
							border: 10px solid $dark-card-background;
						}
					}
					.icon-wrapper{
						background-color: $dark-card-background;
					}
				}
			}
			.follow {
				.follow-num{
					color: $dark-all-font-color;
				}
			}
			.profile-img-style{
				.user-name{
					color: $dark-all-font-color;
				}
				p{
					color: $dark-small-font-color;
				}
			}
		}
		.introjs-helperLayer{
			background-color: rgba(54,64,74,0.9);
			border: rgba(255,255,255,0.5);
		}
		.jstree-default {
			.jstree-node, .jstree-icon{
				color: #434f5a !important;
			}
			.jstree-anchor{
				color: $dark-small-font-color;
			}
			.jstree-clicked{
				color: $white;
				background-color: transparent;
			}
			.jstree-hovered{
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
			}
		}
		span.twitter-typeahead{
			.tt-menu{
				background-color: $dark-body-background;
				color: $dark-all-font-color;
			}
			.tt-suggestion{
				color: $dark-all-font-color;
				&:hover,
				&:focus{
					background-color: $dark-card-background;
				}
			}
		}
		.typography{
			small{
				color: $dark-all-font-color;
			}
		}
		.blockquote-footer{
			color: $dark-small-font-color;
		}
		.quickview-wrapper {
			background-color: $dark-card-background;
			box-shadow: 0 0 9px $dark-border-color;
			border-left: 1px solid $dark-border-color;
			.quickview-box, .quickview-recent-activity {
				&:before {
					background-color: $dark-card-background;
					box-shadow: 0 0 9px $dark-border-color;
					border-left: 1px solid $dark-border-color;
				}
				&:after {
					background-color: $dark-card-background;
				}
			}
			.nav-material.nav-tabs {
				.nav-item {
					.nav-link {
						color: $dark-all-font-color;
					}
				}
			}
			.nav-material.nav-primary {
				.nav-link {
					&.active, :hover {
						color: $primary-color;
						background-color: transparent;
					}
				}
				.nav-item.show, .nav-link:focus {
					color: $primary-color;
					background-color: transparent;
				}
			}
			.modal-header {
				border-bottom: 1px solid $dark-border-color;
				.modal-title {
					color: $dark-all-font-color;
				}
				i {
					color: $dark-small-font-color;
				}
			}
			.friend-list-search {
				background-color: #2f3841;
				input {
					background-color: $dark-body-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
			.recent-activity {
				.activity-content {
					h6 {
						color: $dark-all-font-color;
					}
					hr {
						border-top: 1px solid $dark-border-color;
					}
				}
				.user-first-letter {
					&:after {
						border: 1px dashed $dark-border-color;
					}
				}
			}
			.chat-box{
				.about{
					.name{
						color: $dark-all-font-color;
					}
				}
			}
		}
		.code-box-copy{
			pre{
				background-color: $dark-body-background;
				code{
					background-color: $dark-body-background;
				}
			}
			pre[class*="language-"]{
				border: 1px solid $dark-border-color;
			}
			.code-box-copy__btn{
				background-color: $dark-body-background;
				border: 1px solid $dark-border-color;
				color:$white;
			}
			code[class*=language-], pre[class*=language-]{
				text-shadow: 0px 1px $black;
				::selection{
					text-shadow: none;
					background: $dark-card-background;
				}
			}
		}
		table.fixedHeader-floating{
			background-color: $dark-body-background;
		}
		.dt-button-info{
			background-color: $dark-card-background;
			border: 1px solid $dark-border-color;
			h2{
				background-color: $dark-card-background;
				border-bottom: 1px solid $dark-border-color;
			}
		}
		#keytable_wrapper, #column-selector_wrapper, #auto-fill_wrapper, #custom-button_wrapper, #class-button_wrapper, #keyboard-btn_wrapper, #multilevel-btn_wrapper, #pagelength-btn_wrapper, #basic-colreorder_wrapper, #state-saving_wrapper, #real-time_wrapper, #basic-fixed-header_wrapper, #fixed-header-footer_wrapper, #export-button_wrapper, #excel-cust-bolder_wrapper, #cust-json_wrapper, #basic-key-table_wrapper, #focus-cell_wrapper, #responsive_wrapper, #new-cons_wrapper, #show-hidden-row_wrapper, #basic-row-reorder_wrapper, #full-row_wrapper, #rest-column_wrapper{
			.dataTables_paginate{
				border: none;
			}
		}
		#example-style-8_wrapper{
			table.dataTable.stripe, table.dataTable.display{
				tbody {
					tr.odd{
						background-color: $dark-border-color;
					}
				}
			}
		}
		.error-wrapper{
			background-color: rgba(54, 64, 74, 0.8);
			.sub-content{
				color: $dark-all-font-color;
			}
		}
		.b-light{
			border:1px solid $dark-border-color !important;
		}
		.modal-content{
			background-color: $dark-card-background;
			.modal-header{
				border-bottom: 1px solid $dark-border-color;
			}
			.ui-front{
				.form-control{
					background-color: $dark-card-background;
					color: $dark-all-font-color;
					border: 1px solid $dark-border-color;
				}
			}
		}
		.stepwizard {
			.stepwizard-row{
				&:before{
					background-color: $dark-small-font-color;
				}
			}
		}
		.modal{
			.theme-close{
				background-color: $dark-card-background !important;
			}
		}
		.token.atrule, .token.attr-value, .token.keyword {
			color: $info-color;
		}
		.token{
			&.boolean, &.constant, &.deleted, &.number, &.property, &.symbol, &.tag{
				color: $danger-color;
			}
		}
		.loader-box{
			&.card-loader{
				background-color: $dark-card-background;
			}
		}
		.my-gallery{
			&.gallery-with-description{
				img {
					border: 1px solid $dark-border-color !important;
					border-bottom: none !important;
				}
			}
		}
		@each $var in $alert-name {
			$i: index($alert-name, $var);
			.alert-#{$var}.inverse{
				color: $dark-all-font-color;
			}
		}
	}

	// ========= theme layout 4  scss end ==========//

	//bordered navigation scss start here//
	.page-sidebar {
		&[navigation-layout="navigation-bordered"] {
			.sidebar-header {
				border-bottom: 1px solid $light-color;
			}
		}
	}

	//checkbox bordered navigation scss start here//
	.page-sidebar.navigation-bordered {
		.sidebar-header {
			border-bottom: 1px solid $light-color;
		}
	}
	.page-wrapper {
		.page-body-wrapper {
			.page-sidebar.navigation-rightside {
				.sidebar-header {
					> span {
						margin-left: 5px;
					}
					i {
						right: 20px;
					}
				}
				.sidebar-menu {
					> li {
						> a {
							i {
								margin-right: 0;
								margin-left: 7px;
								&:first-child {
									position: absolute;
								}
								&:last-child {
									float: left;
								}
							}
						}
					}
				}
			}
		}
	}

	// right side icon scss start here
	.page-sidebar {
		&[navigation-layout="right-side-menu"] {
			.sidebar-menu {
				> li {
					> a {
						i {
							&:first-child {
								float: right;
							}
							&:last-child {
								float: left;
							}
						}
					}
				}
			}
		}
	}

	//image background sidebar
	.page-wrapper {
		.page-body-wrapper {
			.page-sidebar.native-image-bg {
				background-image: url("/src/assets/images/sidebar-bg.jpg");
				.sidebar-user {
					background: transparent;
				}
			}
		}
	}
}
/**=====================
   55. theme layout CSS ends
==========================**/
